@import url("assets/css/fonts/fonts.css");
@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
@import url("../src/app/claim-pages/employee-compensation/injured-body-parts/body-parts/body-parts.component.css");

#preload { position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100vw; height: 100vh; z-index: 999999; background: #fff; }
#preload .loading { position: absolute; width: auto; height: auto; margin: auto; top: 0; bottom: 0; left: 0; right: 0; }

.clearfix:after, .clearfix:before{ content:""; display:table; }
.clearfix:after{ clear:both; }
.clearfix{ zoom:1; }
.row {
  display: flex;
  display: -webkit-flex;
  flex-wrap:wrap;
  -webkit-flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
}
.docRow {
  display: flex;
  display: -webkit-flex;
  flex-wrap:wrap;
  -webkit-flex-wrap: wrap;
  overflow-wrap:word-break;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}
.close:focus, .close:hover {
  outline: 0;
  box-shadow: none;
}
.row:before, .row:after{ display: none; }
.ff-stag { font-family: "stag-med", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif; }
body {
  font-family: "Verdana", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif;
  width: 100%;
  color: #303235;
  text-align: center;
  min-width: 360px;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  position: relative !important;
}
a { color: #0064c1; }
a:hover { color: #004687; }
a, a:hover,
.cperson-wrapper,
.hits div {
  -ms-transition: all .2s ease-in-out;
  -moz-transition:all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition:all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
a:hover{ text-decoration: none; }
h1, h2, h3, h4, h5, h6 {
  position: relative;
  z-index: 1;
}
h1 {
  font-family: "stag-med", "Helvetica Neue","Microsoft YaHei", Arial, sans-serif;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0px;
}
h4 {
  font-size: 18px;
  line-height: 22px;
}
h5 {
  font-family: "stag-med", "Helvetica Neue", Arial, "Microsoft YaHei",sans-serif;
  font-size: 22px;
  line-height: 22px;
  color: #282828;
}
div.inline {
  display: inline-block;
}
.fa-save {
  font-size: 16px;
  margin-bottom: -1px;
}
.lang-zh {
  font-family: "Noto Sans TC", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
}
.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  box-shadow: none;
}
.txt-blue { color: #0064c1; }
.txt-white { color: #fff; }
.txt-skyblue { color: #049DE5; }
.logo {
  width: 115px;
  padding: 20px 0;
  vertical-align: bottom;
  margin-right: 15px;
}
.lang-flag {
  width: 20px;
  height: 15px;
  vertical-align: top;
  display: inline-block;
}
.lang-txt {
  display: inline-block;
}
.search-box-div{
  position: relative;
  margin-left: 10px;
}
.search-box-div svg{
  position: absolute;
  left: 18px;
  top: 13px;
}
.search {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #303235;
  padding-top: 20px;
  transition: width 1s;
}
.searchPerson-desc{
  margin-left: 4px;
}
.search input {
  color: #303235;
  /*padding: 0 15px;*/
  display: inline-block;
  width: 105px !important;
  box-sizing: border-box;
  min-width: 0 !important;
}
.search input:focus{
  width: 150px !important;
}
.search input::-webkit-input-placeholder{
  font-weight: normal;
}
.search input:focus::-webkit-input-placeholder{
  color:transparent;
}
.search a.search-box {
  padding: 5px 18px 5px;
  font-weight: normal;
  min-width: auto;
}
.search>div {
  display: inline-block;
}
.search .lang-box a {
  color: #0064c1;
  vertical-align: middle;
}
.home {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg3.jpg);
  background-position: right center;
  position: relative;
}
.home-dmh {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg4-v2.png);
  background-position: -586px;
  position: relative;
  background-repeat: no-repeat;
}

.home-motor {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg5.jpg);
  background-position: right center;
  position: relative;
}

.home-home {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg6.jpg);
  background-position: right center;
  position: relative;
}

.home-pa {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg7.jpg);
  background-position: right center;
  position: relative;
}

.home-ec {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-ec.png);
  background-position: right center;
  position: relative;
}

.home-wrap {
  padding-top: 30px;
  padding-bottom: 30px;
}
.home-wrap h2 {
  font-size: 16px;
  line-height: 20px;
}
.bg-overlay {
  background-color: #0064c1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform: skewX(0deg); /* IE 9 */
  -webkit-transform: skewX(0deg); /* Safari */
  transform: skewX(0deg);
}
.btn {
  /*min-width: 120px;*/
  /*padding: 12px 28px 10px;*/
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}
.btn-hero {
  background-color: #fff;
  color: #0064c1;
  border:0;
  border:2px solid #fff;
}
.btn-hero:hover {
  background-color: transparent;
  color: #fff;
}
.btn-primary {
  background-color: #0064c1;
  color: #fff;
  border:2px solid #0064c1;
}
.btn-primary:hover {
  background-color: #004687;
  color: #fff;
  border:2px solid #004687;
}
.btn-primary:disabled,
.btn-primary[disabled]{
  background-color: #0064c1;
  color: #fff;
  border:2px solid #0064c1;
}
.btn-secondary {
  background-color: transparent;
  color: #0064c1;
  border:2px solid #0064c1;
}
.btn-secondary:hover {
  background-color: rgba(168, 178, 190, 0.2);
  color: #0064c1;
  border:2px solid #0064c1;
}
.btn-secondary-invert {
    background-color: transparent;
    color: #0064c1;
    border: 2px solid #0064c1;
}

.btn-secondary-invert:hover {
    background-color: rgba(0, 70, 135, 0.8);
    color: #fff;
    border: 2px solid #ffffff;
}
.btn-tag {
  background-color: #f1f1f1;
  color: #454545;
  border:2px solid #f1f1f1;
}
.btn-tag:hover {
  background-color: #63666a;
  color: #fff;
  border:2px solid #63666a;
}
.container-center p {
  display: inline-block;
}
.hits {
  font-size: 10px;
  font-style: normal;
  cursor: pointer;
  position: relative;
  height: 20px;
  width: 34px;
  display: inline-block;
  top: -2px;
  left: 5px;
  font-family: "stag-med", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif;
}
.hits:after {
  content: '?';
  line-height: 20px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #009ae4;
  color: #009ae4;
  text-align: center;
  display: inline-block;
}
.hits div {
  position: absolute;
  left: 32px;
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  background-color: #191919;
  padding: 10px 15px;
  color: #fff;
  line-height: 1.4;
  border-radius: 0;
  min-width: 160px;
  max-width: 220px;
  z-index: 999;
  top: -8px;
  transform: none;
  opacity: 0;
  visibility: hidden;
}
.hits ul {
  padding-left: 15px;
}
.hits div:after {
  content: "";
  position: absolute;
  margin-top: 5px;
  margin-left: 11px;
  border-right: 8px solid transparent;
  border-top: 8px solid #191919;
  transform: rotate(-45deg);
  left: -15px;
  top: 10px;
}
.uiLabel .hits ul {
  margin-bottom: 0;
}
.hits:hover div,
.hits div:hover {
  opacity: 1;
  visibility: visible;
}
.home-form {
  background-color: #fff;
  position: relative;
  z-index: 1;
  color: #282828;
  padding: 0;
  max-width: 100%;
  /*margin:0 auto;*/
}
.bg-grey {
  background-color: #f2f2f2;
}
.bg-light-blue {
  background-color: #F1F1F1;
}
.bg-blue {
  background-color: #0064c1;
  color: #fff;
}
.box-wrapper {
  background-color: #fff;
  box-shadow: 2px 2px 4px #ccc;
  padding: 20px 30px;
  border:1px solid #ccc;
  width: fit-content;
}
.home-claim-simple {
  padding-bottom: 60px;
  padding-top: 20px;
  min-height: calc(100vh - 256px);
  margin-top: 0;
}
.container-outter.container-left {
  padding: 15px;
  background-color: #F1F1F1 !important;
}
.container-outter {
  padding: 90px 15px;
}
.prev-bar, .submit-bar { padding: 20px 15px; }
.container-center {
  min-height: calc(100vh - 185px);
}
.container-wrapper {
  margin-left: 0;
  margin-right: 0;
  min-height: calc(100vh - 185px);
}
.container-outter h4 {
  margin-bottom: 0;
}
.container-outter h4 span {
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  display: inline-block;
  margin-top: 5px;
}
.processing-bar {
  list-style-type: none;
  padding-left: 0;
  /*display: none;*/
}
.processing-bar li {
  padding: 8px 0;
}
.processing-bar li ul {
  padding-left: 0;
  list-style-type: none;
  padding-top: 8px;
  color: #303235;
  padding-left: 33px;
}
.processing-bar li ul li {
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  font-size: 14px;
  padding: 5px 0;
  line-height: 1.2;
}
.processing-bar li ul li:before {
  height: 0;
  width: 0;
  border: 0!important;
  overflow: hidden;
  margin-right: 0;
}
.processing-bar li:before {
  height: 24px;
  width: 24px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 6px;
  margin-bottom: -4px;
  background-repeat: no-repeat;
  background-position: center;
}
.processing-bar li.notFinish:before {
  content: '';
  border: 2px solid #303235;
  background-image: url(assets/images/close_grey.svg);
  background-size: 8px;
}
.processing-bar li.active:before {
  content: '';
  border: 2px solid #0e875f;
  background-image: url(assets/images/tick_green.svg);
  background-size: 12px;
}
.processing-bar li.current:before {
  content: '';
  border: 2px solid #0064c1;
  background-image: url(assets/images/pen.svg);
  background-size: 10px;
}
.processing-bar li.active {
  color: #0e875f;
}
.processing-bar li.current {
  color: #0064c1;
}
.ico {
  display: inline-block;
  height: 18px;
  width: 24px;
  background-size: 18px;
  background-position: left bottom;
  background-repeat: no-repeat;
}
.btn-secondary-invert .ico {
  height: 12px;
  width: 12px;
  background-size: 12px;
  margin-left: 2px;
  margin-bottom: -1px;
}
.btn-secondary-invert .ico.ico-save {
  height: 16px;
  width: 16px;
  background-size: 16px;
  margin-bottom: -2px;
}
.ico-tick { background-image: url(assets/images/tick.svg); }
.ico-close { background-image: url(assets/images/close.svg); }
.ico-save { background-image: url(assets/images/save2.svg); }
.processing-bar .active {
  color: #0064c1;
}
.submit-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.prev-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.padding-bottom-30 {
  padding-bottom: 30px;
  margin-bottom: 5%;
}
.tickcircle {
  width: 100px;
  height: 100px;
  padding: 18px;
  margin-bottom: 20px;
  float: left;
}
.tickcircle-text h1 {
  margin-bottom: 0;
}
.tickcircle .svg {
  -webkit-transform: scale(1.8);
  -ms-transform: scale(1.8);
  -o-transform: scale(1.8);
  transform: scale(1.8);
}
.cperson-wrapper {
  padding: 40px 15px;
  margin: 10px auto;
  color: #454545;
  background-color: #ffffff;
  /*border-top:3px solid #c8c8c8;*/
  cursor: pointer;
  background-position: right 10px bottom 10px;
  border-radius: 15px;
}
.cperson-wrapper h5 {
  margin-bottom: 0;
}
.cperson-wrapper:hover h5{
  color: #ffffff;
}
.cperson-wrapper:hover {
  background-color: #63666a;
  /*border-top: 3px solid #9dc3e6;*/
  color: #ffffff;
  fill: #ffffff;
}
.cperson-wrapper:hover .icon path{
  /*fill: #ffffff;*/
  stroke: #ffffff;
}
.cperson-wrapper:hover .icon circle{
  /*fill: #ffffff;*/
  stroke: #ffffff;
}
.cperson-wrapper.active {
  background: #0064c1;
  background-repeat: no-repeat;
  /*border-top: 3px solid #0064c1;*/
  background-position: right 10px bottom -2px;
  background-size: 26px;
  color: #ffffff;
  fill: #ffffff;
}
.cperson-wrapper.active .icon path{
  stroke: #ffffff;
  fill: #ffffff;
}
.cperson-wrapper.active .icon circle{
  stroke: #ffffff;
  /*fill: #ffffff;*/
}
.cperson-wrapper.active .icon line{
  stroke: #ffffff;
  fill: #ffffff;
}
.cperson-wrapper:hover .icon line{
    stroke: #ffffff;
    fill: #ffffff;
}
.cperson-wrapper:hover .icon path{
    stroke: #ffffff;
    fill: #ffffff;
}

.cperson-wrapper.active h5 {
  color: #ffffff;
}
.cpreson-outter svg{
  width: 50px;
  height: 50px;
}
.cpreson-outter circle{
  stroke: #000000;
}

.claimTypeBox .claimType:nth-of-type(4) .cperson-wrapper:hover .icon path{
  fill: none !important;
}
.claimTypeBox .claimType:nth-of-type(4) .cperson-wrapper.active .icon path{
  fill: none !important;
}
.claimTypeBox .claimType:nth-of-type(5) .cperson-wrapper:hover .icon rect{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(5) .cperson-wrapper.active .icon rect{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(6) .cperson-wrapper.active .icon rect{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(6) .cperson-wrapper:hover .icon rect{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(7) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(7) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(10) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(10) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(12) .cperson-wrapper.active .icon rect{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(12) .cperson-wrapper:hover .icon rect{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper .icon path{
  stroke: #000000 !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(15) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(15) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon circle{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon ellipse{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper:hover .icon ellipse{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon ellipse{
  stroke: #ffffff !important;
  fill: none;
}

.ico-claim {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.claim-type .ico-claim {
  margin-bottom: 15px;
}
.claim-details .ico-claim {
  width: 30px;
  vertical-align: bottom;
  margin-right: 5px;
}
.svg-fill-grey path,
.ico-claim circle {
  stroke: transparent;
}
.ico-claim:not(.svg-fill-grey) path,
.ico-claim circle {
  stroke: #282828;
}
.svg-fill-grey path {
  fill: #282828;
}
.cperson-wrapper.active .ico-claim:not(.svg-fill-grey) path,
.cperson-wrapper.active .ico-claim circle {
  stroke: #0064c1;
}
.cpreson-outter .icon path{
  stroke: #0b2e13;
}
.cperson-wrapper.active .svg-fill-grey path {
  fill: #0064c1;
}
.sm-cperson-wrapper {
  padding: 20px 10px;
}
.form-control:focus {
  box-shadow: 0 0 0 1px #009ae4;
  border-color: #009ae4;
}
.form-control {
  font-size: 14px;
  border-radius: 0;
  padding: 13px 20px;
  border-color: #a8b2be;
}
.form-control.inline {
  display: inline-block;
}
.form-control.xs {
  padding: 13px 10px;
}
.index-form label {
  margin-bottom: 6px;
}
.index-form .form-group {
  margin-bottom: 8px;
}
.progress {
  border-radius: 0;
  background-color: #d9d9d9;
  height: 8px;
}
.progress-bar {
  background-color: #0064c1;
}
.pad-top5 {
  padding-top: 5px;
}
/*.click-more-content { display: none; }*/
.cheque-box,.cheque-box:hover {
  background-color: #f8f8f8;
  /*background-color: red;*/
  background-image: url(assets/images/cs-icon.png);
  background-repeat: no-repeat;
  background-size: 70px;
  background-position: left 24px bottom 15px;
  height: 83px;
  width: 350px;
  padding-left: 100px;
  border-radius: 5px;
  color: #303235;
  font-size: 14px;
  display: inline-block;
}
label {
  font-family: "stag-med", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  color: #282828;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: .4rem;
}
select.form-control:not([size]):not([multiple]) {
  height: 49px;
}
.form-text {
  max-width: 410px;
  line-height: 1.2;
  padding: 5px 0;
}
.box-icon {
  width: 40px;
  margin-bottom: 15px;
}
.desktop-ver {
  display: none;
}
.nav-tabs {
  border-bottom: 1px solid #a8b2be;
  margin-bottom: 30px;
}
.nav-tabs a {
  font-size: 18px;
  line-height: 18px;
  padding: 0 10px 8px 10px;
  display: inline-block;
  color: #282828;
  border-bottom: 3px solid #fff;
}
.nav-tabs a.active,
.nav-tabs a:hover {
  color: #0064c1;
  border-bottom: 3px solid #0064c1;
}
.remove-pad-right   { padding-right: 7px; }
.remove-pad-left    { padding-left: 7px; }
.remove-pad-center  { padding-left: 7px; padding-right: 7px; }
.cperson-wrapper br { display: none; }
input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
input[type="file"] ~ label {
  color: #495057;
  background-color: #f1f1f1;
  display: block;
  cursor: pointer;
  border: 1px solid #f8f8f8;
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
}
.ico-editable {
  background-image: url(assets/images/pen-grey.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.datetimepicker1 {
  background-image: url(assets/images/calendar.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.progress{
  margin-bottom: 0 !important;
}
.table-condensed thead>tr>th{
  padding: 5px !important;
}
.dropdown-menu{
  font-size: 0.6rem !important;
}

#PYM03{
  z-index: 25000 !important;
}

.form-group .btn:nth-of-type(2){
  margin-left: 10px;
}
.form-group input.form-control.inline{
  margin-left: 10px;
}
.Tertiary {
  width: 63px;
  height: 21px;
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0064c1;
  /*display: inline-block;*/
  height: 47px;
  line-height: 47px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(assets/images/arrow-down-grey.svg);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: right 10px center;
}
select:focus {
  background-image: url(assets/images/arrow-down.svg);
}
select::-ms-expand {
  display: none;
}
.logo {
  width: 90px;
  padding: 20px 0;
  vertical-align: bottom;
  margin-right: 15px;
}
.search {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #303235;
  padding-top: 15px;
  padding-left: 0;
}
.faq-btn{
  min-width: 70px;
  padding: 2px 5px 2px 2px;
  height: 37px;
}
@media (min-width: 576px) {
  .tickcircle {
    margin-bottom: 0;
  }
  .home-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  h1 {
    font-size: 42px;
    line-height: 48px;
  }
  h5 {
    font-size: 26px;
    line-height: 30px;
  }
  .cperson-wrapper h5 {
    font-size: 22px;
    line-height: 24px;
  }
  .new-btn{
    min-width: 149px;
    padding: 12px 28px 10px;
  }
  .cperson-wrapper.active {
    background-size: 40px;
  }
  body {
    font-size: 16px;
    line-height: 22px;
  }
  .container-outter.container-left {
    padding: 15px 25px;
  }
  .container-outter {
    padding: 90px 25px;
  }
  .prev-bar, .submit-bar { padding: 20px 25px; }
}
@media (min-width: 768px) {
  .cperson-wrapper {
    padding: 40px 15px;
  }
  .cperson-wrapper br {
    display: block;
  }
}
@media (min-width: 992px) {
  .container-center {
    min-height: calc(100vh - 82px);
  }
  .container-outter.less-content {
    padding: 90px 60px;
  }
  .cperson-wrapper {
    margin: 20px auto;
  }
  .container-outter.container-left {
    padding: 40px 25px;
  }
  .processing-bar {
    display: block;
  }
  .container-outter h4 {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .box-wrapper {
    border:1px solid #fff;
    min-height: 300px;
  }
  .home-wrap.container {
    text-align: left;
  }
  .home {
    height: 280px;
  }
  .home-dmh {
    height: 280px;
  }
  .home-motor {
    height: 280px;
  }
  .home-home {
    height: 280px;
  }
  .home-pa {
    height: 280px;
  }
  .home-ec {
    height: 280px;
  }
  .bg-overlay {
    left: -300px;
    width: 85%;
    -ms-transform: skewX(-30deg); /* IE 9 */
    -webkit-transform: skewX(-30deg); /* Safari */
    transform: skewX(-30deg);
  }
  .home-form {
    margin-bottom: 30px;
    padding: 20px 0px 20px 0px;
    max-width: 1080px;
  }
  .home-claim-simple {
    padding-top: 0;
    margin-top: -110px;
  }
  .mobile-ver {
    display: none;
  }
  .desktop-ver {
    display: block;
  }
  .cheque-box {
    position: absolute;
    top: 15%;
    left: 70%;
  }
}
@media (max-width: 991px) {
  .home-claim-simple.bg-grey {
    background-color: #fff;
  }
  .container-left a,
  .container-left h4 {
    display: inline-block;
    vertical-align: middle;
  }
  .reasonTravelDelay{
    height: 120px;
  }
}
@media (max-width: 1199px) {
  .container-right {
    display: none;
  }

}
@media (min-width: 576px) {
  .logo {
    width: 115px;
  }
  .search {
    padding-top: 20px;
  }
  .menu-btn {
    width: 44px;
    height: 57px;
    display: none !important;
  }
}
@media (max-width: 768px) {
  .show-in-mobile{
    display: block;
  }
  .show-in-desktop{
    display: none !important;
  }
  h5{
    font-size: 24px;
  }
}
/* After Tablet */
@media (max-width: 767px) {
  label.uiLabel,
  .auth-nav-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  label .hits,
  .auth-nav-tabs .hits{
    left: 0;
    width: 20px;
  }
  label .hits > div,
  .auth-nav-tabs .hits > div {
    left: unset;
    top: 28px;
    right: -10px;
    transform: none;
  }
  label .hits > div:after,
  .auth-nav-tabs .hits > div:after {
    border-right: 0;
    border-left: 8px solid #191919;
    border-top: 8px solid #191919;
    top: -8px;
    left: unset;
    right: 16px;
  }
}
@media (min-width: 769px) {
  .show-in-mobile{
    display: none  !important; ;
  }
  .show-in-desktop{
    display: block ;
  }

}
@media (max-width: 991px) {
  .menu-btn {
    z-index: 999999;
    display: inline-block !important;
  }
  .home-claim-simple.bg-grey {
    background-color: #fff;
  }
  .desktop-none {
    display: none;
  }
  .container-left a,
  .container-left h4 {
    display: inline-block;
    vertical-align: middle;
  }
  .container-outter.container-left {
    position: absolute;
    top: 0;
    left: -300px;
    z-index: 99999;
    min-height: 100vh;
    width: 300px;
    padding: 82px 15px 15px 15px;
  }
  .container-outter.container-left.opened {
    left: 0;
  }
  nav .container {
    max-width: 100%;
  }
}

@media only screen and (min-width: 384px){
  .logo{
    width: 81px;
  }
}

@media only screen and (max-width: 360px){
  .logo{
    width: 64px;
  }
}

@media only screen and (max-width: 420px){
  .submit-bar {
    position: inherit !important;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .prev-bar {
    position: inherit !important;
    top: 0;
    left: 0;
    width: 100%;
  }
  .container-outter{
    padding: 0px;
    margin-top: 10px;
  }
  .float-right{
    margin-right: 10%;
  }
  .cperson-wrapper{
    word-wrap: break-word; 
  }
  .faq-btn{
    min-width: 50px;
    padding: 2px 5px 2px 2px;
    height: 27px;
  }
  .logo{
    padding: 20px 10px 20px 0px;
    width: 81px;
  }
}

@media only screen and (max-width: 350px) and (orientation : portrait) {

  .home-wrap.container {
    text-align: left;
  }
  .home {
    height: 63%;
    width: 320px;
    color: #fff;
  }
  .logo{
    width: 64px;
  }
  .bg-overlay {
    width: 100%;
    height: 100%;
  }
  .home-form {
    max-width: 320px;
  }
  .home-claim-simple {
    padding-top: 0;
  }
  .mobile-ver {
    display: block;
  }
  nav .container {
    max-width: 100%;
  }
  .search {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #303235;
    padding-top: 15px;
    padding-left: 0;
  }
  .searchPerson-desc{
    font-size: 13px;
    margin-left: 4px;
  }

}

a, a:hover,
.cperson-wrapper,
.hits div,
.menu-btn span,
.container-outter.container-left {
  -ms-transition: all .2s ease-in-out;
  -moz-transition:all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition:all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
.menu-btn {
  width: 36px;
  height: 55px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: -5px;
}
.menu-btn span {
  display: block;
  width: 28px;
  height: 3px;
  background-color: #000;
  position: absolute;
  left: 0;
}
.menu-btn span:nth-child(1) { top: 16px; }
.menu-btn span:nth-child(2) { top: 25px; }
.menu-btn span:nth-child(3) { top: 34px; }
.menu-btn.opened span:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
  top: 11px;
}
.menu-btn.opened span:nth-child(2) {
  opacity: 0;
}
.menu-btn.opened span:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
  top: 37px;
}
.addFile{
    color: rgb(0, 100, 193);
}
.addFile span{
    margin-left: 5px;
}
.no-margin-input{
  margin-left: 0px !important;
}

.new-btn{
  min-width: 149px;
  padding: 12px 28px 10px;
}
.specify{
  margin-left: 0 !important;
  margin-top: 10px;
}
.no-padding{
  padding-left: 0;
}
.removeBtn{
  color: #FFFFFF;
  font-size: 10px;
  background: #0064c1;
  border:none;
  border-radius: 30px;
  margin-left: 10px;
}
.removeDocBtn{
  width:47px;
  height:47px;
  color: #FFFFFF;
  font-size: 10px;
  border:none;
  margin-left: -1px;
  margin-top: 0px;
  background:#2f5597 url('assets/images/delete-x-icon.png') center no-repeat;
  cursor: pointer;
  background-size: 30px;
}
.removeDocFailBtn{
  width:47px;
  height:51px;
  color: #f2dede;
  font-size: 10px;
  border:none;
  margin-left: -1px;
  margin-top: 0px;
  background:#ff0000 url('assets/images/delete-x-icon.png') center no-repeat;
  cursor: pointer;
  background-size: 30px;
}
.white-popup {
  position: relative;
  background: #FFF;
  width: auto;
  max-width: 1100px;
  margin: 30px auto;
  border-radius: 5px;
}
.popup_title {
  padding: 30px 70px 20px 30px;
  border-bottom: 1px solid #e3e3e3;
}
.popup_content.terms {
  padding: 30px 30px 0 50px;
}
.popup_content {
  padding: 30px 30px 0 30px;
}
.popup_content ol {
  font-family: 'stag-semibold';
  padding: 0;
}
.popup_content li {
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
}
.popup_content ol.personal {
  font-family: 'stag-book';
  padding-left: 24px;
}
.popup_content ol.personal li {
  padding: 0 0 0 5px;
  margin:0;
}
.popup_content ol.bullet {
  font-family: 'stag-book';
  list-style-type: disc;
  padding-left: 24px;
}
.popup_content ol.bullet li {
  padding: 0 0 0 5px;
  margin:0;
}
.popup_content ol.tnc {
  padding-left: 14px;
}
.popup_content ol.ol-alphabet{
  list-style-type: lower-alpha;
}
.popup_content li p {
  font-family: 'stag-light';
}
.popup_content a {
  color: #3f454f;
}
.popup_footer {
  padding: 0 30px 50px 30px;
  text-align: center;
}
.reasonTravelDelay{
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
 }
.removeBtn{
  color: #FFFFFF;
  font-size: 10px;
  background: #0064c1;
  border:none;
  border-radius: 30px;
  margin-left: 10px;
}
.countryList{
  position: absolute;
  z-index: 20;
  background: #FFFFFF;
  height: 300px;
  overflow: scroll;
}
.countryList li{
  list-style: none;
  height: 50px;
  line-height: 50px;
}
.countryList{
  position: absolute;
  z-index: 20;
  background: #FFFFFF;
  height: 300px;
  overflow: scroll;
  padding: 0 0 !important;
}
.countryList li{
  list-style: none;
  height: 50px;
  line-height: 50px;
  font-size: 10px;
  padding-left: 5px;
  border-bottom: 1px solid #a8b2be;
}
.specify{
  margin-left: 0 !important;
  margin-top: 10px;
}

.popup_title {
    padding: 30px 70px 20px 30px;
    border-bottom: 1px solid #e3e3e3;
}
.popup_content.terms {
    padding: 30px 30px 0 50px;
}
.popup_content {
    padding: 30px 30px 0 30px;
}
.popup_content ol {
    font-family: 'stag-semibold';
    padding: 0;
}
.popup_content li {
    padding: 0 0 0 10px;
    margin: 0 0 20px 0;
}
.popup_content ol.personal {
    font-family: 'stag-book';
    padding-left: 24px;
}
.popup_content ol.personal li {
    padding: 0 0 0 5px;
    margin:0;
}
.popup_content ol.bullet {
  font-family: 'stag-book';
  list-style-type: disc;
  padding-left: 24px;
}
.popup_content ol.bullet li {
  padding: 0 0 0 5px;
  margin:0;
}
.popup_content ol.tnc {
    padding-left: 14px;
}
.popup_content ol.ol-alphabet{
    list-style-type: lower-alpha;
}
.popup_content li p {
    font-family: 'stag-light';
}
.popup_content a {
    color: #3f454f;
}
.uploadedFileStyle{
  background-color: #0064c1;
  color: #ffffff;
 }
 .file-uploaded{
  background-color: #0064c1;
  color: #ffffff;
}
.file-uploaded-summary{
  color: green;
  word-break: break-all
}
.file-uploaded-summary:before{
  color: green;
  content: "✓";
}
.file-not-uploaded-summary{
  color: red;
}
.file-not-uploaded-summary:before{
  color: red;
  content: "x";
}

.modal-dialog {
  padding-top: 50px;
}

.modal-semibold {
  font-weight: bold;
}
.modal_content {
  padding: 30px 30px 0 30px;
  text-align: justify;
}
.modal_content ol {
  padding: 0;
}
.modal_content li {
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
}
.modal_content ol.personal {
  padding-left: 24px;
}
.modal_content ol.personal li {
  padding: 0 0 0 5px;
  margin:0;
}
.modal_content ol.bullet {
  padding-left: 24px;
  list-style-type: disc;
}
.modal_content ol.bullet li {
  padding: 0 0 0 5px;
  margin:0;
}
.modal_content ol.ol-alphabet{
  list-style-type: lower-alpha;
}
.box1 p {
  margin-bottom: 0px;
}
.bottom-box p {
  font-size: 10px;
  line-height: 10px;
}

.remove-claim-details:before{
  content: '';
  display: inline-block;
  height: 24px;
  width: 24px;
  background-image: url(assets/images/delete-icon.svg);
  background-size: 22px;
  margin-right: 6px;
  margin-bottom: -4px;
  background-repeat: no-repeat;
  background-position: center;
}

.btn-helper {
  background-color: #d9dadc;
  color: #0064c1;
}
.btn-helper:hover {
  background-color: #b8bcbf;
}
.complaint_body{
    font-size:  14px;
}
.cpreson-outter-motor-icons svg{
  width: 70px;
  height: 70px;
}
.cpreson-outter-motor-icons circle{
  stroke: #000000;
}
.cpreson-outter-motor-icons .icon path{
  stroke: #0b2e13;
}
.read-more-links {
  color: #0064c1 !important;
}
.use-underline {
  text-decoration: underline;
}

.col-lg-5-word-break {
  -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
  max-width: 41.666667%;
  word-break: break-word;
}

.modal-title {
  font-family: 'stag-med', sans-serif;
}

/** MOBILE **/
@media only screen and (min-width: 320px) and (max-width: 812px) {
  .col-lg-5-word-break {
      -ms-flex: 0%;
      flex: 1 0 41.666667%;
      max-width: 50%;
      word-break: break-word;
  }
  .home-home {
    height: auto;
  }
} 

/** TABLETS **/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .col-lg-5-word-break {
      -ms-flex: 0%;
      flex: 0%;
      max-width: 100%;
      word-break: break-word;
  }
} 

ol.lower-roman {
  list-style-type: lower-roman;
  list-style-position: inside;
}

.dropdown-list {
  overflow: hidden;
}

.multiselect-dropdown .dropdown-btn {
  padding: 11px 18px !important;
  font-size: 14px !important;
  border-radius: 0 !important;
  color: #7b8a8b;
}

.dropdown-multiselect__caret {
  display: none !important;
}

.divider {
  border-top: 3px solid #bbb;
  margin: 15px;
}
