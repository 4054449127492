@import url("assets/css/fonts/fonts.css");
@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
@import url("../src/app/claim-pages/employee-compensation/injured-body-parts/body-parts/body-parts.component.css");

@font-face {
  font-family: 'NotoSans-Bold';
  src: url('/claims/assets/fonts/NotoSans/NotoSansTC-Bold.woff') format('woff'),
  url('/claims/assets/fonts/NotoSans/NotoSansTC-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: url('/claims/assets/fonts/NotoSans/NotoSansTC-Medium.woff') format('woff'),
  url('/claims/assets/fonts/NotoSans/NotoSansTC-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-Light';
  src: url('/claims/assets/fonts/NotoSans/NotoSansTC-Light.woff') format('woff'),
  url('/claims/assets/fonts/NotoSans/NotoSansTC-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue-Bold';
  src: url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Bold.woff') format('woff'),
  url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue-Book';
  src: url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Book.woff') format('woff'),
  url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue-Demi';
  src: url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Demi.woff') format('woff'),
  url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Demi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue-Light';
  src: url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Light.woff') format('woff'),
  url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue-Medium';
  src: url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Medium.woff') format('woff'),
  url('/claims/assets/fonts/MaisonNeue/MaisonNeue-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body .mox-style {
  font-family: "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif !important;
}

#preload { position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100vw; height: 100vh; z-index: 999999; background: #fff; }
#preload .loading { position: absolute; width: auto; height: auto; margin: auto; top: 0; bottom: 0; left: 0; right: 0; }

.clearfix:after, .clearfix:before{ content:""; display:table; }
.clearfix:after{ clear:both; }
.clearfix{ zoom:1; }
.row {
  display: flex;
  display: -webkit-flex;
  flex-wrap:wrap;
  -webkit-flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
}
.docRow {
  display: flex;
  display: -webkit-flex;
  flex-wrap:wrap;
  -webkit-flex-wrap: wrap;
  overflow-wrap:word-break;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}
.close:focus, .close:hover {
  outline: 0;
  box-shadow: none;
}
.row:before, .row:after{ display: none; }
.ff-stag { font-family: "stag-med", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif; }
body {
  font-family: "Verdana", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif;
  width: 100%;
  color: #303235;
  text-align: center;
  min-width: 360px;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  position: relative !important;
}
a { color: #0064c1; }
a:hover { color: #004687; }
a, a:hover,
.cperson-wrapper,
.hits div {
  -ms-transition: all .2s ease-in-out;
  -moz-transition:all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition:all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
a:hover{ text-decoration: none; }
h1, h2, h3, h4, h5, h6 {
  position: relative;
  z-index: 1;
}
h1 {
  font-family: "stag-med", "Helvetica Neue","Microsoft YaHei", Arial, sans-serif;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0px;
}
h4 {
  font-size: 18px;
  line-height: 22px;
}
h5 {
  font-family: "stag-med", "Helvetica Neue", Arial, "Microsoft YaHei",sans-serif;
  font-size: 22px;
  line-height: 22px;
  color: #282828;
}
div.inline {
  display: inline-block;
}
.fa-save {
  font-size: 16px;
  margin-bottom: -1px;
}
.lang-zh {
  font-family: "Noto Sans TC", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
}
.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  box-shadow: none;
}
.txt-blue { color: #0064c1; }
.txt-white { color: #fff; }
.txt-skyblue { color: #049DE5; }
.logo {
  width: 115px;
  padding: 20px 0;
  vertical-align: bottom;
  margin-right: 15px;
}
.lang-flag {
  width: 20px;
  height: 15px;
  vertical-align: top;
  display: inline-block;
}
.lang-txt {
  display: inline-block;
}
.search-box-div{
  position: relative;
  margin-left: 10px;
}
.search-box-div svg{
  position: absolute;
  left: 18px;
  top: 13px;
}
.search {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #303235;
  padding-top: 20px;
  transition: width 1s;
}
.searchPerson-desc{
  margin-left: 4px;
}
.search input {
  color: #303235;
  /*padding: 0 15px;*/
  display: inline-block;
  width: 105px !important;
  box-sizing: border-box;
  min-width: 0 !important;
}
.search input:focus{
  width: 150px !important;
}
.search input::-webkit-input-placeholder{
  font-weight: normal;
}
.search input:focus::-webkit-input-placeholder{
  color:transparent;
}
.search a.search-box {
  padding: 5px 18px 5px;
  font-weight: normal;
  min-width: auto;
}
.search>div {
  display: inline-block;
}
.search .lang-box a {
  color: #0064c1;
  vertical-align: middle;
}
.home {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg3.jpg);
  background-position: right center;
  position: relative;
}
.home-dmh {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg4-v2.png);
  background-position: -586px;
  position: relative;
  background-repeat: no-repeat;
}

.home-motor {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg5.jpg);
  background-position: right center;
  position: relative;
}

.home-home {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg6.jpg);
  background-position: right center;
  position: relative;
}

.home-pa {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-bg7.jpg);
  background-position: right center;
  position: relative;
}

.home-ec {
  color: #fff;
  height: auto;
  background-image: url(assets/images/home-ec.png);
  background-position: right center;
  position: relative;
}

.home-wrap {
  padding-top: 30px;
  padding-bottom: 30px;
}
.home-wrap h2 {
  font-size: 16px;
  line-height: 20px;
}
.bg-overlay {
  background-color: #0064c1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform: skewX(0deg); /* IE 9 */
  -webkit-transform: skewX(0deg); /* Safari */
  transform: skewX(0deg);
}
.btn {
  /*min-width: 120px;*/
  /*padding: 12px 28px 10px;*/
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}
.btn-hero {
  background-color: #fff;
  color: #0064c1;
  border:0;
  border:2px solid #fff;
}
.btn-hero:hover {
  background-color: transparent;
  color: #fff;
}
.btn-primary {
  background-color: #0064c1;
  color: #fff;
  border:2px solid #0064c1;
}
.btn-primary:hover {
  background-color: #004687;
  color: #fff;
  border:2px solid #004687;
}
.btn-primary:disabled,
.btn-primary[disabled]{
  background-color: #0064c1;
  color: #fff;
  border:2px solid #0064c1;
}
.btn-secondary {
  background-color: transparent;
  color: #0064c1;
  border:2px solid #0064c1;
}
.btn-secondary:hover {
  background-color: rgba(168, 178, 190, 0.2);
  color: #0064c1;
  border:2px solid #0064c1;
}
.btn-secondary-invert {
    background-color: transparent;
    color: #0064c1;
    border: 2px solid #0064c1;
}

.btn-secondary-invert:hover {
    background-color: rgba(0, 70, 135, 0.8);
    color: #fff;
    border: 2px solid #ffffff;
}
.btn-tag {
  background-color: #f1f1f1;
  color: #454545;
  border:2px solid #f1f1f1;
}
.btn-tag:hover {
  background-color: #63666a;
  color: #fff;
  border:2px solid #63666a;
}
.container-center p {
  display: inline-block;
}
.hits {
  font-size: 10px;
  font-style: normal;
  cursor: pointer;
  position: relative;
  height: 20px;
  width: 34px;
  display: inline-block;
  top: -2px;
  left: 5px;
  font-family: "stag-med", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif;
}
.hits:after {
  content: '?';
  line-height: 18px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #009ae4;
  color: #009ae4;
  text-align: center;
  display: inline-block;
}
.hits div {
  position: absolute;
  left: 32px;
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  background-color: #191919;
  padding: 10px 15px;
  color: #fff;
  line-height: 1.4;
  border-radius: 0;
  min-width: 160px;
  max-width: 220px;
  z-index: 999;
  top: -8px;
  transform: none;
  opacity: 0;
  visibility: hidden;
}
.hits ul {
  padding-left: 15px;
}
.hits div:after {
  content: "";
  position: absolute;
  margin-top: 5px;
  margin-left: 11px;
  border-right: 8px solid transparent;
  border-top: 8px solid #191919;
  transform: rotate(-45deg);
  left: -15px;
  top: 10px;
}
.uiLabel .hits ul {
  margin-bottom: 0;
}
.hits:hover div,
.hits div:hover {
  opacity: 1;
  visibility: visible;
}
.home-form {
  background-color: #fff;
  position: relative;
  z-index: 1;
  color: #282828;
  padding: 0;
  max-width: 100%;
  /*margin:0 auto;*/
}
.bg-grey {
  background-color: #f2f2f2;
}
.bg-light-blue {
  background-color: #F1F1F1;
}
.bg-blue {
  background-color: #0064c1;
  color: #fff;
}
.box-wrapper {
  background-color: #fff;
  box-shadow: 2px 2px 4px #ccc;
  padding: 20px 30px;
  border:1px solid #ccc;
  width: fit-content;
}
.home-claim-simple {
  padding-bottom: 60px;
  padding-top: 20px;
  min-height: calc(100vh - 256px);
  margin-top: 0;
}
.container-outter.container-left {
  padding: 15px;
  background-color: #F1F1F1 !important;
}
.container-outter {
  padding: 90px 15px;
}
.prev-bar, .submit-bar { padding: 20px 15px; }
.container-center {
  min-height: calc(100vh - 185px);
}
.container-wrapper {
  margin-left: 0;
  margin-right: 0;
  min-height: calc(100vh - 185px);
}
.container-outter h4 {
  margin-bottom: 0;
}
.container-outter h4 span {
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  display: inline-block;
  margin-top: 5px;
}
.processing-bar {
  list-style-type: none;
  padding-left: 0;
  /*display: none;*/
}
.processing-bar li {
  padding: 8px 0;
}
.processing-bar li ul {
  padding-left: 0;
  list-style-type: none;
  padding-top: 8px;
  color: #303235;
  padding-left: 33px;
}
.processing-bar li ul li {
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  font-size: 14px;
  padding: 5px 0;
  line-height: 1.2;
}
.processing-bar li ul li:before {
  height: 0;
  width: 0;
  border: 0!important;
  overflow: hidden;
  margin-right: 0;
}
.processing-bar li:before {
  height: 24px;
  width: 24px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 6px;
  margin-bottom: -4px;
  background-repeat: no-repeat;
  background-position: center;
}
.processing-bar li.notFinish:before {
  content: '';
  border: 2px solid #303235;
  background-image: url(assets/images/close_grey.svg);
  background-size: 8px;
}
.processing-bar li.active:before {
  content: '';
  border: 2px solid #0e875f;
  background-image: url(assets/images/tick_green.svg);
  background-size: 12px;
}
.processing-bar li.current:before {
  content: '';
  border: 2px solid #0064c1;
  background-image: url(assets/images/pen.svg);
  background-size: 10px;
}
.processing-bar li.active {
  color: #0e875f;
}
.processing-bar li.current {
  color: #0064c1;
}
.ico {
  display: inline-block;
  height: 18px;
  width: 24px;
  background-size: 18px;
  background-position: left bottom;
  background-repeat: no-repeat;
}
.btn-secondary-invert .ico {
  height: 12px;
  width: 12px;
  background-size: 12px;
  margin-left: 2px;
  margin-bottom: -1px;
}
.btn-secondary-invert .ico.ico-save {
  height: 16px;
  width: 16px;
  background-size: 16px;
  margin-bottom: -2px;
}
.ico-tick { background-image: url(assets/images/tick.svg); }
.ico-close { background-image: url(assets/images/close.svg); }
.ico-save { background-image: url(assets/images/save2.svg); }
.processing-bar .active {
  color: #0064c1;
}
.submit-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.prev-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.padding-bottom-30 {
  padding-bottom: 30px;
  margin-bottom: 5%;
}
.tickcircle {
  width: 100px;
  height: 100px;
  padding: 18px;
  margin-bottom: 20px;
  float: left;
}
.tickcircle-text h1 {
  margin-bottom: 0;
}
.tickcircle .svg {
  -webkit-transform: scale(1.8);
  -ms-transform: scale(1.8);
  -o-transform: scale(1.8);
  transform: scale(1.8);
}
.cperson-wrapper {
  padding: 40px 15px;
  margin: 10px auto;
  color: #454545;
  background-color: #ffffff;
  /*border-top:3px solid #c8c8c8;*/
  cursor: pointer;
  background-position: right 10px bottom 10px;
  border-radius: 15px;
}
.cperson-wrapper h5 {
  margin-bottom: 0;
}
.cperson-wrapper:hover h5{
  color: #ffffff;
}
.cperson-wrapper:hover {
  background-color: #63666a;
  /*border-top: 3px solid #9dc3e6;*/
  color: #ffffff;
  fill: #ffffff;
}
.cperson-wrapper:hover .icon path{
  /*fill: #ffffff;*/
  stroke: #ffffff;
}
.cperson-wrapper:hover .icon circle{
  /*fill: #ffffff;*/
  stroke: #ffffff;
}
.cperson-wrapper.active {
  background: #0064c1;
  background-repeat: no-repeat;
  /*border-top: 3px solid #0064c1;*/
  background-position: right 10px bottom -2px;
  background-size: 26px;
  color: #ffffff;
  fill: #ffffff;
}
.cperson-wrapper.active .icon path{
  stroke: #ffffff;
  fill: #ffffff;
}
.cperson-wrapper.active .icon circle{
  stroke: #ffffff;
  /*fill: #ffffff;*/
}
.cperson-wrapper.active .icon line{
  stroke: #ffffff;
  fill: #ffffff;
}
.cperson-wrapper:hover .icon line{
    stroke: #ffffff;
    fill: #ffffff;
}
.cperson-wrapper:hover .icon path{
    stroke: #ffffff;
    fill: #ffffff;
}

.cperson-wrapper.active h5 {
  color: #ffffff;
}
.cpreson-outter svg{
  width: 50px;
  height: 50px;
}
.cpreson-outter circle{
  stroke: #000000;
}

.claimTypeBox .claimType:nth-of-type(4) .cperson-wrapper:hover .icon path{
  fill: none !important;
}
.claimTypeBox .claimType:nth-of-type(4) .cperson-wrapper.active .icon path{
  fill: none !important;
}
.claimTypeBox .claimType:nth-of-type(5) .cperson-wrapper:hover .icon rect{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(5) .cperson-wrapper.active .icon rect{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(6) .cperson-wrapper.active .icon rect{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(6) .cperson-wrapper:hover .icon rect{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(7) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(7) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(10) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(10) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(12) .cperson-wrapper.active .icon rect{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(12) .cperson-wrapper:hover .icon rect{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper .icon path{
  stroke: #000000 !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(15) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(15) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon circle{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper:hover .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon path{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon ellipse{
  stroke: #ffffff !important;
  fill: #ffffff;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper:hover .icon ellipse{
  stroke: #ffffff !important;
  fill: none;
}
.claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon ellipse{
  stroke: #ffffff !important;
  fill: none;
}

.ico-claim {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.claim-type .ico-claim {
  margin-bottom: 15px;
}
.claim-details .ico-claim {
  width: 30px;
  vertical-align: bottom;
  margin-right: 5px;
}
.svg-fill-grey path,
.ico-claim circle {
  stroke: transparent;
}
.ico-claim:not(.svg-fill-grey) path,
.ico-claim circle {
  stroke: #282828;
}
.svg-fill-grey path {
  fill: #282828;
}
.cperson-wrapper.active .ico-claim:not(.svg-fill-grey) path,
.cperson-wrapper.active .ico-claim circle {
  stroke: #0064c1;
}
.cpreson-outter .icon path{
  stroke: #0b2e13;
}
.cperson-wrapper.active .svg-fill-grey path {
  fill: #0064c1;
}
.sm-cperson-wrapper {
  padding: 20px 10px;
}
.form-control:focus {
  box-shadow: 0 0 0 1px #009ae4;
  border-color: #009ae4;
}
.form-control {
  font-size: 14px;
  border-radius: 0;
  padding: 13px 20px;
  border-color: #a8b2be;
}
.form-control.inline {
  display: inline-block;
}
.form-control.xs {
  padding: 13px 10px;
}
.index-form label {
  margin-bottom: 6px;
}
.index-form .form-group {
  margin-bottom: 8px;
}
.progress {
  border-radius: 0;
  background-color: #d9d9d9;
  height: 8px;
}
.progress-bar {
  background-color: #0064c1;
}
.pad-top5 {
  padding-top: 5px;
}
/*.click-more-content { display: none; }*/
.cheque-box,.cheque-box:hover {
  background-color: #f8f8f8;
  /*background-color: red;*/
  background-image: url(assets/images/cs-icon.png);
  background-repeat: no-repeat;
  background-size: 70px;
  background-position: left 24px bottom 15px;
  height: 83px;
  width: 350px;
  padding-left: 100px;
  border-radius: 5px;
  color: #303235;
  font-size: 14px;
  display: inline-block;
}
label {
  font-family: "stag-med", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  color: #282828;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: .4rem;
}
select.form-control:not([size]):not([multiple]) {
  height: 49px;
}
.form-text {
  max-width: 410px;
  line-height: 1.2;
  padding: 5px 0;
}
.box-icon {
  width: 40px;
  margin-bottom: 15px;
}
.desktop-ver {
  display: none;
}
.nav-tabs {
  border-bottom: 1px solid #a8b2be;
  margin-bottom: 30px;
}
.nav-tabs a {
  font-size: 18px;
  line-height: 18px;
  padding: 0 10px 8px 10px;
  display: inline-block;
  color: #282828;
  border-bottom: 3px solid #fff;
}
.nav-tabs a.active,
.nav-tabs a:hover {
  color: #0064c1;
  border-bottom: 3px solid #0064c1;
}
.remove-pad-right   { padding-right: 7px; }
.remove-pad-left    { padding-left: 7px; }
.remove-pad-center  { padding-left: 7px; padding-right: 7px; }
.cperson-wrapper br { display: none; }
input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
input[type="file"] ~ label {
  color: #495057;
  background-color: #f1f1f1;
  display: block;
  cursor: pointer;
  border: 1px solid #f8f8f8;
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
}
.ico-editable {
  background-image: url(assets/images/pen-grey.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.datetimepicker1 {
  background-image: url(assets/images/calendar.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.progress{
  margin-bottom: 0 !important;
}
.table-condensed thead>tr>th{
  padding: 5px !important;
}
.dropdown-menu{
  font-size: 0.6rem !important;
  z-index: 1001;
}

#PYM03{
  z-index: 25000 !important;
}

.form-group .btn:nth-of-type(2){
  margin-left: 10px;
}
.form-group input.form-control.inline{
  margin-left: 10px;
}
.Tertiary {
  width: 63px;
  height: 21px;
  font-family: "Verdana", "Helvetica Neue", "Microsoft YaHei", Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0064c1;
  /*display: inline-block;*/
  height: 47px;
  line-height: 47px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(assets/images/arrow-down-grey.svg);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: right 10px center;
}
select:focus {
  background-image: url(assets/images/arrow-down.svg);
}
select::-ms-expand {
  display: none;
}
.logo {
  width: 90px;
  padding: 20px 0;
  vertical-align: bottom;
  margin-right: 15px;
}
.search {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #303235;
  padding-top: 15px;
  padding-left: 0;
}
.faq-btn{
  min-width: 70px;
  padding: 2px 5px 2px 2px;
  height: 37px;
}
@media (min-width: 576px) {
  .tickcircle {
    margin-bottom: 0;
  }
  .home-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  h1 {
    font-size: 42px;
    line-height: 48px;
  }
  h5 {
    font-size: 26px;
    line-height: 30px;
  }
  .cperson-wrapper h5 {
    font-size: 22px;
    line-height: 24px;
  }
  .new-btn{
    min-width: 149px;
    padding: 12px 28px 10px;
  }
  .cperson-wrapper.active {
    background-size: 40px;
  }
  body {
    font-size: 16px;
    line-height: 22px;
  }
  .container-outter.container-left {
    padding: 15px 25px;
  }
  .container-outter {
    padding: 90px 25px;
  }
  .prev-bar, .submit-bar { padding: 20px 25px; }
}
@media (min-width: 768px) {
  .cperson-wrapper {
    padding: 40px 15px;
  }
  .cperson-wrapper br {
    display: block;
  }
}
@media (min-width: 992px) {
  .container-center {
    min-height: calc(100vh - 82px);
  }
  .container-outter.less-content {
    padding: 90px 60px;
  }
  .cperson-wrapper {
    margin: 20px auto;
  }
  .container-outter.container-left {
    padding: 40px 25px;
  }
  .processing-bar {
    display: block;
  }
  .container-outter h4 {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .box-wrapper {
    border:1px solid #fff;
    min-height: 300px;
  }
  .home-wrap.container {
    text-align: left;
  }
  .home {
    height: 280px;
  }
  .home-dmh {
    height: 280px;
  }
  .home-motor {
    height: 280px;
  }
  .home-home {
    height: 280px;
  }
  .home-pa {
    height: 280px;
  }
  .home-ec {
    height: 280px;
  }
  .bg-overlay {
    left: -300px;
    width: 85%;
    -ms-transform: skewX(-30deg); /* IE 9 */
    -webkit-transform: skewX(-30deg); /* Safari */
    transform: skewX(-30deg);
  }
  .home-form {
    margin-bottom: 30px;
    padding: 20px 0px 20px 0px;
    max-width: 1080px;
  }
  .home-claim-simple {
    padding-top: 0;
    margin-top: -110px;
  }
  .mobile-ver {
    display: none;
  }
  .desktop-ver {
    display: block;
  }
  .cheque-box {
    position: absolute;
    top: 15%;
    left: 70%;
  }
}
@media (max-width: 991px) {
  .home-claim-simple.bg-grey {
    background-color: #fff;
  }
  .container-left a,
  .container-left h4 {
    display: inline-block;
    vertical-align: middle;
  }
  .reasonTravelDelay{
    height: 120px;
  }
}
@media (max-width: 1199px) {
  .container-right {
    display: none;
  }

}
@media (min-width: 576px) {
  .logo {
    width: 115px;
  }
  .search {
    padding-top: 20px;
  }
  .menu-btn {
    width: 44px;
    height: 57px;
    display: none !important;
  }
}
@media (max-width: 768px) {
  .show-in-mobile{
    display: block;
  }
  .show-in-desktop{
    display: none !important;
  }
  h5{
    font-size: 24px;
  }
}
/* After Tablet */
@media (max-width: 767px) {
  label.uiLabel,
  .auth-nav-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  label .hits,
  .auth-nav-tabs .hits{
    left: 0;
    width: 20px;
  }
  label .hits > div,
  .auth-nav-tabs .hits > div {
    left: unset;
    top: 28px;
    right: -10px;
    transform: none;
  }
  label .hits > div:after,
  .auth-nav-tabs .hits > div:after {
    border-right: 0;
    border-left: 8px solid #191919;
    border-top: 8px solid #191919;
    top: -8px;
    left: unset;
    right: 16px;
  }
}
@media (min-width: 769px) {
  .show-in-mobile{
    display: none  !important; ;
  }
  .show-in-desktop{
    display: block ;
  }

}
@media (max-width: 991px) {
  .menu-btn {
    z-index: 999999;
    display: inline-block !important;
  }
  .home-claim-simple.bg-grey {
    background-color: #fff;
  }
  .desktop-none {
    display: none;
  }
  .container-left a,
  .container-left h4 {
    display: inline-block;
    vertical-align: middle;
  }
  .container-outter.container-left {
    position: absolute;
    top: 0;
    left: -300px;
    z-index: 99999;
    min-height: 100vh;
    width: 300px;
    padding: 82px 15px 15px 15px;
  }
  .container-outter.container-left.opened {
    left: 0;
  }
  nav .container {
    max-width: 100%;
  }
}

@media only screen and (min-width: 384px){
  .logo{
    width: 81px;
  }
}

@media only screen and (max-width: 360px){
  .logo{
    width: 64px;
  }
}

@media only screen and (max-width: 420px){
  .submit-bar {
    position: inherit !important;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .prev-bar {
    position: inherit !important;
    top: 0;
    left: 0;
    width: 100%;
  }
  .container-outter{
    padding: 10px 0 0 0;
    margin-top: 0;
  }
  .float-right{
    margin-right: 10%;
  }
  .cperson-wrapper{
    word-wrap: break-word; 
  }
  .faq-btn{
    min-width: 50px;
    padding: 2px 5px 2px 2px;
    height: 27px;
  }
  .logo{
    padding: 20px 10px 20px 0px;
    width: 81px;
  }
}

@media only screen and (max-width: 350px) and (orientation : portrait) {

  .home-wrap.container {
    text-align: left;
  }
  .home {
    height: 63%;
    width: 320px;
    color: #fff;
  }
  .logo{
    width: 64px;
  }
  .bg-overlay {
    width: 100%;
    height: 100%;
  }
  .home-form {
    max-width: 320px;
  }
  .home-claim-simple {
    padding-top: 0;
  }
  .mobile-ver {
    display: block;
  }
  nav .container {
    max-width: 100%;
  }
  .search {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #303235;
    padding-top: 15px;
    padding-left: 0;
  }
  .searchPerson-desc{
    font-size: 13px;
    margin-left: 4px;
  }

}

a, a:hover,
.cperson-wrapper,
.hits div,
.menu-btn span,
.container-outter.container-left {
  -ms-transition: all .2s ease-in-out;
  -moz-transition:all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition:all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
.menu-btn {
  width: 36px;
  height: 55px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: -5px;
}
.menu-btn span {
  display: block;
  width: 28px;
  height: 3px;
  background-color: #000;
  position: absolute;
  left: 0;
}
.menu-btn span:nth-child(1) { top: 16px; }
.menu-btn span:nth-child(2) { top: 25px; }
.menu-btn span:nth-child(3) { top: 34px; }
.menu-btn.opened span:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
  top: 11px;
}
.menu-btn.opened span:nth-child(2) {
  opacity: 0;
}
.menu-btn.opened span:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
  top: 37px;
}
.addFile{
    color: rgb(0, 100, 193);
}
.addFile span{
    margin-left: 5px;
}
.no-margin-input{
  margin-left: 0px !important;
}

.new-btn{
  min-width: 149px;
  padding: 12px 28px 10px;
}
.specify{
  margin-left: 0 !important;
  margin-top: 10px;
}
.no-padding{
  padding-left: 0;
}
.removeBtn{
  color: #FFFFFF;
  font-size: 10px;
  background: #0064c1;
  border:none;
  border-radius: 30px;
  margin-left: 10px;
}
.removeDocBtn{
  width:47px;
  height:47px;
  color: #FFFFFF;
  font-size: 10px;
  border:none;
  margin-left: -1px;
  margin-top: 0px;
  background:#2f5597 url('assets/images/delete-x-icon.png') center no-repeat;
  cursor: pointer;
  background-size: 30px;
}
.removeDocFailBtn{
  width:47px;
  height:51px;
  color: #f2dede;
  font-size: 10px;
  border:none;
  margin-left: -1px;
  margin-top: 0px;
  background:#ff0000 url('assets/images/delete-x-icon.png') center no-repeat;
  cursor: pointer;
  background-size: 30px;
}
.white-popup {
  position: relative;
  background: #FFF;
  width: auto;
  max-width: 1100px;
  margin: 30px auto;
  border-radius: 5px;
}
.popup_title {
  padding: 30px 70px 20px 30px;
  border-bottom: 1px solid #e3e3e3;
}
.popup_content.terms {
  padding: 30px 30px 0 50px;
}
.popup_content {
  padding: 30px 30px 0 30px;
}
.popup_content ol {
  font-family: 'stag-semibold';
  padding: 0;
}
.popup_content li {
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
}
.popup_content ol.personal {
  font-family: 'stag-book';
  padding-left: 24px;
}
.popup_content ol.personal li {
  padding: 0 0 0 5px;
  margin:0;
}
.popup_content ol.bullet {
  font-family: 'stag-book';
  list-style-type: disc;
  padding-left: 24px;
}
.popup_content ol.bullet li {
  padding: 0 0 0 5px;
  margin:0;
}
.popup_content ol.tnc {
  padding-left: 14px;
}
.popup_content ol.ol-alphabet{
  list-style-type: lower-alpha;
}
.popup_content li p {
  font-family: 'stag-light';
}
.popup_content a {
  color: #3f454f;
}
.popup_footer {
  padding: 0 30px 50px 30px;
  text-align: center;
}
.reasonTravelDelay{
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
 }
.removeBtn{
  color: #FFFFFF;
  font-size: 10px;
  background: #0064c1;
  border:none;
  border-radius: 30px;
  margin-left: 10px;
}
.countryList{
  position: absolute;
  z-index: 20;
  background: #FFFFFF;
  height: 300px;
  overflow: scroll;
}
.countryList li{
  list-style: none;
  height: 50px;
  line-height: 50px;
}
.countryList{
  position: absolute;
  z-index: 20;
  background: #FFFFFF;
  height: 300px;
  overflow: scroll;
  padding: 0 0 !important;
}
.countryList li{
  list-style: none;
  height: 50px;
  line-height: 50px;
  font-size: 10px;
  padding-left: 5px;
  border-bottom: 1px solid #a8b2be;
}
.specify{
  margin-left: 0 !important;
  margin-top: 10px;
}

.popup_title {
    padding: 30px 70px 20px 30px;
    border-bottom: 1px solid #e3e3e3;
}
.popup_content.terms {
    padding: 30px 30px 0 50px;
}
.popup_content {
    padding: 30px 30px 0 30px;
}
.popup_content ol {
    font-family: 'stag-semibold';
    padding: 0;
}
.popup_content li {
    padding: 0 0 0 10px;
    margin: 0 0 20px 0;
}
.popup_content ol.personal {
    font-family: 'stag-book';
    padding-left: 24px;
}
.popup_content ol.personal li {
    padding: 0 0 0 5px;
    margin:0;
}
.popup_content ol.bullet {
  font-family: 'stag-book';
  list-style-type: disc;
  padding-left: 24px;
}
.popup_content ol.bullet li {
  padding: 0 0 0 5px;
  margin:0;
}
.popup_content ol.tnc {
    padding-left: 14px;
}
.popup_content ol.ol-alphabet{
    list-style-type: lower-alpha;
}
.popup_content li p {
    font-family: 'stag-light';
}
.popup_content a {
    color: #3f454f;
}
.uploadedFileStyle{
  background-color: #0064c1;
  color: #ffffff;
 }
 .file-uploaded{
  background-color: #0064c1;
  color: #ffffff;
}
.file-uploaded-summary{
  color: green;
  word-break: break-all
}
.file-uploaded-summary:before{
  color: green;
  content: "✓";
}
.file-not-uploaded-summary{
  color: red;
}
.file-not-uploaded-summary:before{
  color: red;
  content: "x";
}

.modal-dialog {
  padding-top: 50px;
}

.modal-semibold {
  font-weight: bold;
}
.modal_content {
  padding: 30px 30px 0 30px;
  text-align: justify;
}
.modal_content ol {
  padding: 0;
}
.modal_content li {
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
}
.modal_content ol.personal {
  padding-left: 24px;
}
.modal_content ol.personal li {
  padding: 0 0 0 5px;
  margin:0;
}
.modal_content ol.bullet {
  padding-left: 24px;
  list-style-type: disc;
}
.modal_content ol.bullet li {
  padding: 0 0 0 5px;
  margin:0;
}
.modal_content ol.ol-alphabet{
  list-style-type: lower-alpha;
}
.box1 p {
  margin-bottom: 0px;
}
.bottom-box p {
  font-size: 10px;
  line-height: 10px;
}

.remove-claim-details:before{
  content: '';
  display: inline-block;
  height: 24px;
  width: 24px;
  background-image: url(assets/images/delete-icon.svg);
  background-size: 22px;
  margin-right: 6px;
  margin-bottom: -4px;
  background-repeat: no-repeat;
  background-position: center;
}

.btn-helper {
  background-color: #d9dadc;
  color: #0064c1;
}
.btn-helper:hover {
  background-color: #b8bcbf;
}
.complaint_body{
    font-size:  14px;
}
.cpreson-outter-motor-icons svg{
  width: 70px;
  height: 70px;
}
.cpreson-outter-motor-icons circle{
  stroke: #000000;
}
.cpreson-outter-motor-icons .icon path{
  stroke: #0b2e13;
}
.read-more-links {
  color: #0064c1 !important;
}
.use-underline {
  text-decoration: underline;
}

.col-lg-5-word-break {
  -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
  max-width: 41.666667%;
  word-break: break-word;
}

.modal-title {
  font-family: 'stag-med', sans-serif;
}

/** MOBILE **/
@media only screen and (min-width: 320px) and (max-width: 812px) {
  .col-lg-5-word-break {
      -ms-flex: 0%;
      flex: 1 0 41.666667%;
      max-width: 50%;
      word-break: break-word;
  }
  .home-home {
    height: auto;
  }
} 

/** TABLETS **/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .col-lg-5-word-break {
      -ms-flex: 0%;
      flex: 0%;
      max-width: 100%;
      word-break: break-word;
  }
} 

ol.lower-roman {
  list-style-type: lower-roman;
  list-style-position: inside;
}

.dropdown-list {
  overflow: hidden;
}

.multiselect-dropdown .dropdown-btn {
  padding: 11px 18px !important;
  font-size: 14px !important;
  border-radius: 0 !important;
  color: #7b8a8b;
}

.dropdown-multiselect__caret {
  display: none !important;
}

.divider {
  border-top: 3px solid #bbb;
  margin: 15px;
}

.uploadFileContainer.droppedFile label {
  border: 0;
  word-break: break-all;
  background-color: #0064c1;
}

/* MOX - Global Styles - Start */
:root {
  --primary-color: #00FAFF;
  --muted-color: #EDEDED;
  --border-separator-color: #B4B4B4;
  --inactive-input-text-color: #B4B4B4;
  --form-border-color: #EDEDED;
  --radio-default-border-color: #979797;
  --checkbox-default-border-color: #979797;
  --black: #000;
  --white: #fff;
  --mox-white: #f4f4f4;
  --mox-off-white: #f8f8f8;
  --mox-off-black: #505050;
  --highlight-bg-color: #DBFFFF;
  --link-color: #000000;
  --container-side-padding: 30px;
  --money-field-currency-field-width-10gap: calc(40% - 5px);
  --money-field-amount-field-width-10gap: calc(60% - 5px);
  --error-primary: #EA064E;
  --error-secondary: #FBE0E7;
  --placeholder-color: #757575;
  --gray400: #ADADAD;
  --gray500: #757575;
  --gray700: #505050;
}

.mox-style h5 {
  font-family: "MaisonNeue-Book", "MaisonNeue-Light", sans-serif;
  font-size: 20px;
  line-height: 24px;
}
.mox-style p {
  font-size: 14px;
}
.mox-style label {
  font-family: "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
  font-size: 18px;
  line-height: 18px;
}
.mox-style .formNote {
  font-size: 12px;
}

.progress.mox-style {
  background-color:var(--muted-color);
}
.progress.mox-style [role="progressbar"] {
  background-color: var(--primary-color);
  display: block;
}
.mox-style .processing-bar li.current {
  color: unset;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.mox-style .processing-bar li.current:before {
  background-image: unset;
  background-size: 12px;
}
.mox-style .processing-bar li.active:before {
  content: '';
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  background-image: url(/claims/assets/images/icon_check.svg);
  background-size: 12px;
}
.mox-style .processing-bar li {
  padding: 5px 0;
  margin: 8px 0 8px 10px;
  /*background-color: var(--mox-white); uncomment for MOX UI - OFFSET LINE*/
  font-family: "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
}
.mox-style .processing-bar li:first-child {
  margin-top: 0;
  padding-top: 0; /* remove for MOX UI - OFFSET LINE */
}
.mox-style .processing-bar li:last-child {
  margin-bottom: 0;
  padding-bottom: 0; /* remove for MOX UI - OFFSET LINE */
}
.mox-style .processing-bar li:before {
  border: 2px solid var(--primary-color);
  margin-right: 10px;
}
.mox-style .processing-bar li.current > span {
  font-size: 18px;
  line-height: 25px;
  /*width: calc(100% - 53px);*/
}
.mox-style .removeBtn {
  color: var(--black);
  font-family: "MaisonNeue-Bold", "MaisonNeue-Medium", sans-serif;
  font-size: 12px;
  line-height: 12px;
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid var(--black);
}
.mox-style .processing-bar li > .removeBtn {
  background: var(--mox-white);
  margin-left: 30px;
  margin-top: 0;
}
.mox-style div:empty  {
  display: none;
}
.mox-style .container-center.container-outter {
  max-width: 100%;
  width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
.mox-style .pa-form-getStarted [class^="col-"] {
  width: 100%;
  max-width: 100%;
}

.mox-style .form-group label {
  font-family: "MaisonNeue-Medium", "MaisonNueu-Book", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}
.mox-style .form-group.ui-error input.ng-invalid,
.mox-style .form-group.ui-error input.ng-valid,
.mox-style .form-group.ui-error select.ng-invalid,
.mox-style .form-group.ui-error textarea.ng-invalid {
  border: 1px solid red !important;
  background: transparent;
  position: relative;
  box-shadow: none;
}
.mox-style .form-group.ui-error ul li small#error-message {
  font-family: "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
  font-size: 12px;
}
.mox-style .form-group.ui-error input:focus {
  box-shadow: none;
}
.mox-style small#error-message {
  width: 100%;
  display: block;
  border-top: 1px solid red;
  padding-top: 5px;
  font-family: "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
  font-size: 12px;
}
.mox-style li.text-danger small#error-message {
  border-top: 0;
}
.mox-style .alert-danger.error-notification {
  border: 0;
  border-radius: 0;
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
}
.mox-style .alert-danger.error-notification.file-error,
.mox-style .summary-of-claim-container .error-notification .row {
  border: 1px solid var(--error-primary);
  border-radius: 8px;
  background: var(--error-secondary);
  padding: 10px 20px;
}
.mox-style .alert-danger.error-notification.file-error li {
  font-size: 11px;
  line-height: 18px;
}
.mox-style .alert-danger.error-notification ul#error-list {
  list-style: none;
  text-align: center;
  margin-top: 0;
  font-size: 12px;
  margin-bottom: 5px;
}

.mox-style .form-control::placeholder {
  color: var(--placeholder-color)
}
.mox-style .form-control {
  border-color: var(--muted-color) !important;
  border-radius: 8px;
  color: var(--black);
}
.mox-style .form-group select.form-control {
  background-size: 14px;
}
.mox-style .container-outter {
  background-color: var(--white);
}
.mox-style [class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.mox-style .row {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.mox-style .mox-style.authentication-component {
  padding: 40px var(--container-side-padding);
}
.mox-style .submit-bar {
  position: unset;
  display: flex;
  justify-content: center;
  padding: 0;
  flex-direction: column;
  gap: 15px;
}
.mox-style .submit-bar .btn {
  width: 100%;
  font-family: "MaisonNeue-Light", "MaisonNeue-Medium", sans-serif;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mox-style .submit-bar .btn-primary {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: var(--white);
  background-color: var(--black);
  padding: 20px;
}
.mox-style .submit-bar .btn-secondary {
  background-color: transparent;
  padding: 20px;
  color: var(--black);
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  display: flex;
  align-items: center;
}
.mox-style .submit-bar .btn svg {
  display: none;
}

:not(.mox-style) .prev-bar .back-chevron {
  display: none;
}
.mox-style .prev-bar {
  top: -72px;
  left: -10px;
  width: 70px
}
.mox-style .prev-bar .back-chevron {
  display: block;
  background-image: url("/claims/assets/images/chevron-back.svg");
  background-repeat: no-repeat;
  background-size: 25px;
  width: 25px;
  height: 25px;
}
.mox-style .prev-bar .btn {
  display: none;
}

.mox-style .radioButtonsMarginTop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.mox-style label.ui-radio {
  width: calc(50% - 20px);
  margin-right: 0;
}
.mox-style qnect-ui .radioButtonsMarginTop {
  gap: 5px;
}
.mox-style label span.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  width: 100%;
  padding: 14px 28px;
  font-family: "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid var(--form-border-color);
  background-color: transparent;
}
.mox-style label span.btn-primary {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}
.mox-style label span.btn-secondary {
  border: 1px solid var(--form-border-color);
  background-color: transparent;
  color: var(--black);
}
.mox-style label span.btn-primary:link,
.mox-style label span.btn-primary:active,
.mox-style label span.btn-primary:focus,
.mox-style label span.btn-primary:hover {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--black) !important;
}
.mox-style .hits:after {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.mox-style label span.btn-secondary:link,
.mox-style label span.btn-secondary:active,
.mox-style label span.btn-secondary:focus,
.mox-style label span.btn-secondary:hover {
  border: 1px solid var(--form-border-color);
  background-color: transparent;
  color: var(--black);
}

.mox-style .radioButtonsMarginTop > div {
  width: 100%;
  flex-basis: 100%;
}
.mox-style label[ng2filedrop].hasFile svg.fa-check,
.mox-style label[ng2filedrop].hasFile svg.svg-inline--fa {
  display: none !important;
}
.mox-style .addFile svg.svg-inline--fa {
  display: none;
}
.mox-style textarea:focus,
.mox-style textarea:active,
.mox-style select:focus,
.mox-style select:active,
.mox-style input:focus,
.mox-style input:active,
.mox-style input[type="radio"]:focus,
.mox-style input[type="radio"]:active {
  border-color: var(--primary-color);
  accent-color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
  transition: none;
}
.mox-style .hide {
  display: none;
}

.mox-style a,
.mox-style a:visited,
.mox-style a:link,
.mox-style a:focus,
.mox-style a:hover {
  color: var(--link-color);
  text-decoration: underline;
}
.mox-style.acknowledgement-container a.btn-cancel,
.mox-style.complete-bg a.btn {
  text-decoration: none !important;
}
/*.mox-style a:hover {*/
/*  text-decoration: underline;*/
/*}*/
.mox-style .info-box {
  background-color: var(--highlight-bg-color) !important;
  border-color: var(--primary-color) !important;
  border-radius: 8px;
}
.mox-style .input-group-prepend {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid var(--form-border-color);
}
.mox-style .input-group-prepend span.input-group-text {
  border-radius: 8px !important;
  border: 0 !important;
  background: none;
}
.mox-style .divider {
  margin: 0;
  border: 0;
  border-bottom: 1px solid var(--border-separator-color);
}

.mox-style.complete-bg .complete-heading .tickcircle .svg {
  transform: scale(1);
}
.mox-style.complete-bg .complete-heading .tickcircle .svg circle {
  fill: var(--black);
  stroke: var(--black);
}

.mox-style [class^="modal"] {
  font-size: 12px;
  line-height: 16px;
  font-family: "MaisonNeue-Light", "MaisonNeue-Book", sans-serif;
}
.mox-style [class^="modal"] h5 {
  font-family: "MaisonNeue-Bold", "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
}
.mox-style .modal-header {
  padding: 25px 30px 20px 30px;
  border-bottom: 1px solid var(--primary-color);
  background: var(--highlight-bg-color);
}
.mox-style .modal-header button.close {
  display: flex;
  align-items: center;
  height: 60px;
}
.mox-style .modal-footer > div {
  margin-right: 0;
  margin-left: 0;
  display: flex;
  justify-content: center;
}
.mox-style .modal-footer {
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.mox-style [class^="modal"] a {
  font-family: "MaisonNeue-Medium", "MaisonNeue-Bold", "MaisonNeue-Book", sans-serif;
}
.mox-style .modal-footer button {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--black);
  color: var(--white);
  padding: 20px;
  font-family: "MaisonNeue-Light", "MaisonNeue-Book", sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 19px;
  max-width: 550px;
}
.mox-style .modal-footer .modal-btn-secondary {
  color: var(--black);
  font-size: 14px;
  background: transparent;
  border: 0;
}
.mox-style .modal-body .modal-semibold {
  font-family: "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
  font-size: 14px;
}
.mox-style .modal-body.policy-content {
  line-height: 18px;
}
.mox-style .modal-body.policy-content ol li {
  margin: 8px 0;
}
.mox-style .modal-body {
  font-family: "MaisonNeue-Medium", "MaisonNeue-Light", "MaisonNeue-Book", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 20px 30px 0 30px;
  text-align: left;
}
.modal-sm .mox-style .modal-footer > div,
.modal-md .mox-style .modal-footer > div,
.mox-style .modal-footer [class^=col] {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}


.mox-style .cperson-wrapper:hover .icon circle {/*fill: var(--black);*/ stroke: var(--black);}
.mox-style .cperson-wrapper.active {color: var(--black); fill: var(--black);}
.mox-style .cperson-wrapper.active .icon path {stroke: var(--black); fill: var(--black);}
.mox-style .cperson-wrapper.active .icon circle {stroke: var(--black); /*fill: var(--black);*/}
.mox-style .cperson-wrapper.active .icon line {stroke: var(--black); fill: var(--black);}
.mox-style .cperson-wrapper:hover .icon line {stroke: var(--black); fill: var(--black);}
.mox-style .cperson-wrapper:hover .icon path {stroke: var(--black); fill: var(--black);}
.mox-style .cperson-wrapper.active h5 {color: var(--black);}
.mox-style .cpreson-outter circle {stroke: #000000;}
.mox-style .cperson-wrapper:hover h5 {color: var(--black);}
.mox-style .cperson-wrapper:hover {color: var(--black); fill: var(--black);}
.mox-style .claimTypeBox .claimType:nth-of-type(4) .cperson-wrapper:hover .icon path {fill: none !important;}
.mox-style .claimTypeBox .claimType:nth-of-type(4) .cperson-wrapper.active .icon path {fill: none !important;}
.mox-style .claimTypeBox .claimType:nth-of-type(5) .cperson-wrapper:hover .icon rect {stroke: var(--black) !important; fill: var(--black);}
.mox-style .claimTypeBox .claimType:nth-of-type(5) .cperson-wrapper.active .icon rect {stroke: var(--black) !important; fill: var(--black);}
.mox-style .claimTypeBox .claimType:nth-of-type(6) .cperson-wrapper.active .icon rect {stroke: var(--black) !important; fill: var(--black);}
.mox-style .claimTypeBox .claimType:nth-of-type(6) .cperson-wrapper:hover .icon rect {stroke: var(--black) !important; fill: var(--black);}
.mox-style .claimTypeBox .claimType:nth-of-type(7) .cperson-wrapper:hover .icon path {stroke: var(--black) !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(7) .cperson-wrapper.active .icon path {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(10) .cperson-wrapper.active .icon path {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(10) .cperson-wrapper:hover .icon path {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(12) .cperson-wrapper.active .icon rect {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(12) .cperson-wrapper:hover .icon rect {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper .icon path {stroke: #000000 !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper:hover .icon path {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(14) .cperson-wrapper.active .icon path {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(15) .cperson-wrapper.active .icon path {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(15) .cperson-wrapper:hover .icon path {stroke: #ffffff !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon circle {stroke: #ffffff !important; fill: #ffffff;}
.mox-style .claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper:hover .icon path {stroke: var(--black) !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon path {stroke: var(--black) !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper:hover .icon ellipse {stroke: var(--black) !important; fill: none;}
.mox-style .claimTypeBox .claimType:nth-of-type(16) .cperson-wrapper.active .icon ellipse {stroke: var(--black) !important; fill: none;}
.mox-style .ico-claim {-webkit-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1);}

.mox-style .claimTypeBox .cperson-wrapper:hover .icon .not-sure-icon path.path1 {fill: none;}
.mox-style .claimTypeBox .cperson-wrapper:hover .icon .not-sure-icon path.path2 {fill: none;}
.mox-style .claimTypeBox .cperson-wrapper:hover .icon .not-sure-icon path.path3 {fill: none;}
.mox-style .claimTypeBox .cperson-wrapper:hover .icon .not-sure-icon path.path4 {fill: none;}
.mox-style .claimTypeBox .cperson-wrapper:hover .icon .not-sure-icon path.path5 {fill: var(--black); fill-opacity: 1;}

/* Mox Custom component background color */
.mox-style app-acknowledgement {
  width: 100%;
  background: var(--highlight-bg-color);
}

.mox-style .hits div {
  background-color: var(--muted-color);
  color: var(--black);
  border-radius: 8px;
}
.mox-style .hits div:after {
  border-top: 8px solid var(--muted-color);
  border-left: 8px solid var(--muted-color);
}

.mox-style .container-outter.container-left {
  position: absolute;
  top: 0;
  left: -400px;
  z-index: 99999;
  min-height: 100vh;
  width: 400px;
  max-width: 400px;
  padding: 82px 15px 15px 15px;
}
.mox-style .container-outter.container-left.opened {
  left: 0;
}

.mox-style .file-not-uploaded-summary{
  color: var(--black);
}

@media screen and (max-width: 575px) {
  .mox-style .container-center.container-outter {
    background: var(--white);
    flex-basis: 100%;
  }
  .mox-style .processing-bar li:before {
    margin-right: 6px;
  }
  .mox-style .processing-bar li > .removeBtn {
    margin-top: 0;
  }
  .mox-style .processing-bar li.current > span {
    font-size: 16px;
  }
  .mox-style .prev-bar {
    left: 0;
  }
  .mox-style .submit-bar {
    width: 100%;
  }
  .mox-style .hits div {
    position: absolute;
    right: -15px;
    left: unset;
    font-family: "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
    font-size: 12px;
    line-height: 18px;
    padding: 15px 20px;
    color: var(--black);
    border-radius: 8px;
    min-width: 160px;
    max-width: 290px;
    width: 260px;
    z-index: 9;
    top: 30px;
    transform: none;
  }
  .mox-style .hits div:after {
    border-left: 8px solid transparent;
    border-right: 0;
    right: 20px;
    left: unset;
    top: -9px;
  }
  .mox-style .hits ul {
    margin-bottom: 0;
  }
  .mox-style .form-group ui-error ul {
    margin-top: 5px;
  }
  .mox-style .submit-bar :first-child {
    order: 3;
  }
  .mox-style .submit-bar :last-child {
    order: 1;
  }
  .mox-style .submit-bar :nth-child(2) {
    order: 2;
  }
  .mox-style .submit-bar .Tertiary {
    width: 100%;
    font-family: "MaisonNeue-Light", "MaisonNeue-Medium", sans-serif;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 20px;
    color: var(--black);
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
  }
  .mox-style .cpreson-outter svg {
    width: 24px;
    height: 24px;
  }
  .mox-style .cperson-wrapper.active {
    color: initial;
    fill: initial;
  }
  .mox-style.bank-account-body .cperson-wrapper.active .cpreson-outter .icon path {
    stroke: var(--black);
    fill: var(--black);
  }
  .mox-style a,
  .mox-style a:hover,
  .mox-style .cperson-wrapper,
  .mox-style .hits div,
  .mox-style .menu-btn span,
  .mox-style .container-outter.container-left {
    transition: none !important;
  }
  .mox-style textarea {
    resize: none;
  }

  .mox-style.bank-account-body label[for="FPS_PROXY_ID_TYPE"] {
    display: flex;
    margin-bottom: 15px;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .flex-container .radioCard {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 20px 30px 20px 25px;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .flex-container .radioCard label {
    margin-bottom: 0;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .flex-container .radioCard.selectedCard input[type="radio"] {
    accent-color: var(--primary-color);
    transform: scale(2);
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .flex-container .radioCard.selectedCard span {
    color: var(--black);
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .flex-container .radioCard.selectedCard input[type="radio"]:checked {
    outline-color: var(--primary-color);
  }

  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .flex-container {
    padding: 5px 0;
    gap: 0;
    border: 1px solid var(--form-border-color);
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard input[type="radio"] {
    display: none;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard {
    padding: 24px 15px !important;
    margin: 0 15px;
    border: 0;
    border-bottom: 1px solid var(--form-border-color);
    border-radius: 0;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard:last-of-type {
    border-bottom: 0;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard label {
    width: 100%;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard.selectedCard label {
    font-style: normal;
    font-weight: bold;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard label.ui-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard label.ui-label span {
    font-size: 14px;
    font-weight: 400;
    color: var(--black);
    line-height: 24px;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard label.ui-label::after {
    content: "";
    width: 24px;
    height: 24px;
    background: none;
    border: 1px solid var(--radio-default-border-color);
    border-radius: 24px;
  }
  .mox-style.bank-account-body qnect-ui[name="fpsProxyIdType"] .radioCard.selectedCard label.ui-label::after {
    content: "";
    background: var(--primary-color) url("/claims/assets/images/icon_check.svg") center center no-repeat;
    border-color: var(--primary-color);
  }
  .mox-style .modal-footer button.btn {
    margin-left: 0;
    margin-right: 0;
  }
  .mox-style .processing-bar li {
    margin: 15px 0 15px 10px;
  }

  /* Mox Custom component style */
  .mox-style app-another-insurance {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) {
  .mox-style .processing-bar li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 24px;
  }
  .mox-style .processing-bar li:before {
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 420px){
  .mox-style .container-outter {
    margin-top: 0;
  }
  .mox-style .prev-bar {
    position: absolute !important;
  }
}

/* Tablet */

@media only screen and (min-width: 576px) and (max-width: 1024px) {
  .mox-style .menu-btn {
    z-index: 999999;
    display: inline-block !important;
  }
  .mox-style .home-claim-simple.bg-grey {
    background-color: #fff;
  }
  .mox-style .desktop-none {
    display: none;
  }
  .mox-style .container-left a,
  .mox-style .container-left h4 {
    display: inline-block;
    vertical-align: middle;
  }
  .mox-style nav .container {
    max-width: 100%;
  }
  .mox-style .control-label {
    text-align: left;
  }
  .mox-style .container-center.container-outter {
    flex-basis: 100%;
  }
  .mox-style .prev-bar {
    left: 0;
  }
  .mox-style .processing-bar li {
    margin: 15px 0 15px 10px;
  }

  /* .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0;
  } */
}

/* Chinese Text Mox */

body .mox-style.lang-zh,
.mox-style.lang-zh label,
.mox-style.lang-zh .processing-bar li,
.mox-style.lang-zh .form-group label,
.mox-style.lang-zh h5,
.mox-style.lang-zh .form-group.ui-error ul li small#error-message,
.mox-style.lang-zh small#error-message,
.mox-style.lang-zh label span.btn,
.mox-style.lang-zh [class^="modal"] a,
.mox-style.lang-zh .modal-body .modal-semibold,
.mox-style.lang-zh .modal-body {
  font-family: "NotoSans-Medium", "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif !important;
}
.mox-style.lang-zh .processing-bar li > .removeBtn,
.mox-style.lang-zh [class^="modal"] h5 {
  font-family: "NotoSans-Bold", "MaisonNeue-Bold", "MaisonNeue-Medium", sans-serif;
}
.mox-style.lang-zh .submit-bar .btn,
.mox-style.lang-zh [class^="modal"],
.mox-style.lang-zh .modal-footer button {
  font-family: "NotoSans-Light", "MaisonNeue-Light", "MaisonNeue-Medium", sans-serif;
}
@media screen and (max-width: 575px) {
  .mox-style.lang-zh .hits div {
    font-family: "NotoSans-Medium", "MaisonNeue-Medium", "MaisonNeue-Book", sans-serif;
  }
  .mox-style.lang-zh .submit-bar .Tertiary {
    font-family: "NotoSans-Light", "MaisonNeue-Light", "MaisonNeue-Medium", sans-serif;
  }
}

/* MOX - Global Styles - End */


/* NOT-MOX New styles */
/* pa-upload-doc.component */
:not(.mox-style) .uploadDocLabel {
  display: block;
}
:not(.mox-style) .uploadDocLabel-mox {
  display: none;
}
:not(.mox-style) .addFile img.plus-img {
  display: none;
}
/* complete.component */
.complete-bg:not(.mox-style) .tickcircle-text h1 {
  text-transform: uppercase;
}
.complete-bg:not(.mox-style) ul.claim-type-docs-detail {
  padding-left:20px;
  margin-bottom: 0;
}
/* summary-of-claim.component */
.summary-of-claim-container:not(.mox-style) .section-header > .section-header-desc {
  display: none;
}
.summary-of-claim-container:not(.mox-style) .attached-file-section .documents-row {
  margin-bottom: 10px;
}
.summary-of-claim-container:not(.mox-style) .attached-file-section .section-header > .section-title {
  display: none;
}
.summary-of-claim-container:not(.mox-style) .attached-file-section .section-header > .supporting-docs-title,
.summary-of-claim-container:not(.mox-style) .attached-file-section .section-header > .other-docs-title {
  display: block;
}
/* header.component */
nav:not(.mox-style) .mox-logo {
  display: none;
}

/* STICKY CTA Mox */
@media screen and (max-width: 1024px) {
  .mox-style .accident-details-container .submit-bar,
  .mox-style .select-claim-container .submit-bar,
  .mox-style .others-notSure-container .submit-bar,
  .mox-style .accidental-medical-expenses-container .submit-bar,
  .mox-style .another-insurance-container .submit-bar,
  .mox-style .summary-of-claim-container .submit-bar,
  .mox-style.complete-bg .bottom-buttons-container {
    position: fixed !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 0 30px 0;
    background: var(--white);
    z-index: 9000;
  }
  .mox-style .accident-details-container .submit-bar button,
  .mox-style .select-claim-container .submit-bar button,
  .mox-style .others-notSure-container .submit-bar button,
  .mox-style .accidental-medical-expenses-container .submit-bar button,
  .mox-style .another-insurance-container .submit-bar button,
  .mox-style .summary-of-claim-container .submit-bar button {
    width: 520px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 575px) {
  .mox-style .accident-details-container .submit-bar,
  .mox-style .select-claim-container .submit-bar,
  .mox-style .others-notSure-container .submit-bar,
  .mox-style .accidental-medical-expenses-container .submit-bar,
  .mox-style .another-insurance-container .submit-bar,
  .mox-style .summary-of-claim-container .submit-bar,
  .mox-style.complete-bg .bottom-buttons-container {
    padding: 20px 30px 15px 30px;
  }
  .mox-style .accident-details-container .submit-bar button,
  .mox-style .select-claim-container .submit-bar button,
  .mox-style .others-notSure-container .submit-bar button,
  .mox-style .accidental-medical-expenses-container .submit-bar button,
  .mox-style .another-insurance-container .submit-bar button,
  .mox-style .summary-of-claim-container .submit-bar button {
    width: 100%;
    margin: 0 auto;
  }
}

