

body {
    margin: 0;
    padding: 0;
    position: relative;
}


/* UI BODY PARTS STYLING */
.modal_open{
    overflow: hidden;
  }
  
  .modal-container {
    padding: 30px;
  }
  
  .dashboard {
    background: #fff;
    width: 25%;
    height: 100vh;
  }
  
  .body_content_container {
    width: 100%;
    /*max-width: 1903px;
    margin: 0 auto; */
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  ._modal {
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
    font-family: "stag-med", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif;
  }
  
  .modal_container {
    position: relative;
    top: 50%;
    left: 50%;
    width: calc(100vw - 32px);
    max-width: 600px;
    height: calc(100vh - 5rem);
    transform: translate(-50%, -50%);
    transition: all 500ms ease-in-out;
    background: white;
    z-index: 2;
    animation: animatetop 225ms;
    box-shadow:
      0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    padding: 0 35px;
    padding-bottom: 40px;
    padding-top: 24px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .modal_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  .modal_container::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  
  .modal_container::-webkit-scrollbar-thumb {
    background-color: #0000002e;
  }
  
  .modal_container h3 {
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 0.03em;
  }
  
  .modal_container .span_area{
    margin-bottom: 30px;
    font-size: 18px;
    display: block;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }
  
  .active {
    fill: #00693c;
  }
  
  svg g:hover path, svg g:hover polygon, svg g:hover rect {
    fill: #003da5;
    fill-opacity: 0.25;
    cursor: pointer;
  }
  
  .vertical-tabs {
    width: 300px;
    margin-left: 20px;
  }
  
  .tooltip_body {
    position: absolute;
    font-size: 14px;
    /* background-color: rgba(0, 0, 0, 0.8); */
    background-color: #191919;
    color: white;
    padding: 5px;
    border-radius: 5px;
    pointer-events: none;
    white-space: nowrap;
    z-index: 99999;
    font-family: Arial, Helvetica, sans-serif;
    /* transform: translate(200%, 200%) !important; */
  }
  
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value > .ng-value-icon {
    order: 2 !important;
    display: unset !important;
    color: #000000 !important;
    border-left: 1px solid transparent !important;
  }
  
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value > .ng-value-label {
    order: 1 !important;
    border-right: unset !important;
    border-left: unset !important;
    display: unset !important;
  }
  
  /* .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    visibility: hidden;
  } */
  
  .ng-select .ng-select-container:after {
    border-bottom: unset !important;
  }
  
  table.body_table, table.body_table thead, table.body_table tbody {
    margin: 0;
    width: 100%;
    height: auto;
    display: block;
  }
  
  table.body_table thead tr, table.body_table tbody tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    width: 100%;
    height: auto;
  }
  
  table.body_table tbody tr {
    align-items: center;
  }
  
  table.body_table thead tr th, table.body_table tbody tr td {
    width: 20%;
    padding: 0;
    text-align: center;
  }
  
  table.body_table tbody tr td p, table.body_table tbody tr td div p {
    margin: 0;
    padding: 0;
  }
  
  .injury_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    width: 100%;
    height: auto;
  }
  
  .body_section {
    display: block;
    /* width: 75%; */
    width: 100%;
    height: auto;
    position: relative;
    /* width: 60%; */
    /* margin-left: auto; */
  }
  
  .body_wrapper {
    padding: 0;
    display: block;
    position: relative;
    font-family: "stag-med", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif;
  }
  
  .body_table_outer_container {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    /* right: 15px; */
    top: 157px;
  }
  
  .body_table_container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    z-index: 1;
  }
  
  .tab_body_container {
    width: 100%;
    height: auto;
    display: block;
    z-index: 1;
    position: relative;
  }
  
  .txt_views {
    font-weight: 600;
    color: #003da5;
    font-size: 21px;
    letter-spacing: 0.02em;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
    position: absolute;
    left: 64%;
    transform: translate(50%, 62%);
  }
  
  .body_table_wrapper {
    position: relative;
    z-index: 2;
    height: 100%;
  }
  
  /* .body_table_wrapper.modal_body_table_wrapper{
    height: 350px;
  } */
  
  .body_table_main{
    /* height: 100%; */
    /* height: 870px; */
    height: 50.3vw;
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .body_table_main::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  .body_table_main::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  
  .body_table_main::-webkit-scrollbar-thumb {
    background-color: #0000002e;
  }
  
  .modal_body_table_wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  .modal_body_table_wrapper::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  
  .modal_body_table_wrapper::-webkit-scrollbar-thumb {
    background-color: #0000002e;
  }
  
  .body_table_left {
    width: 39%;
  }
  
  .body_table_right {
    width: 61%;
  }
  .tab_content_container {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .tab_content_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    width: 100%;
    height: auto;
    margin-top: 27px;
  }
  
  .tab_content_left svg {
    height: 100%;
    width: 100%;
  }
  
  .tab_content_right {
    width: 62%;
  }
  
  table, th, td {
    /*border: 1px solid black;*/
    border-collapse: collapse;
  }
  
  .gender_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: #f7f8f9;
    padding: 15px 0;
    width: 30%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  
  .gender_container button {
    width: 48%;
    height: 65px;
    border: 0;
    border: 1px solid #dedede;
    opacity: 0.6;
    padding: 0;
    position: relative;
    overflow: hidden;  
    cursor: pointer;
  }
  
  .gender_container button:focus{
    outline: unset;
  }
  
  .gender_container button.active_male::before, .gender_container button.active_female::before {
    content: "";
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    background: #003da5;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  
  .gender_container button:hover::before {
    content: "";
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  
  .gender_container button.active_male:hover::before, .gender_container button.active_female:hover::before {
    content: "";
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    background: #003da5;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  
  .gender_container button:disabled,
  .gender_container button[disabled]{
    cursor: not-allowed;
  }
  
  .gender_container button:disabled:hover::before, .gender_container button[disabled]:hover::before {
    opacity: 0;
  }
  
  .gender_container button img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scale(1.05);
  }
  
  .gender_container button.active_male, .gender_container button.active_female {
    opacity: 1;
  }
  
  .txt_wrapper {
    width: 100%;
    margin-bottom: 12px;
    text-align: center;
  }
  
  .txt_wrapper span {
    font-weight: 600;
    color: #003da5;
    font-size: 21px;
    letter-spacing: 0.02em;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .body_table_wrapper table {
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.02em;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
  }
  
  .tb_bd_collection {
    border-right: unset;
    border-left: unset;
  }
  
  .tb_bd_collection tr th {
    color: #00205b;
    padding: 15px 30px;
    border-right: unset;
    border-left: unset;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0;
    /* letter-spacing: -0.01em; */
  }
  
  .tb_bd_collection thead tr {
    background: #f7f8f9;
    border: 1px solid #a7aebb;
    border-right: unset;
    border-left: unset;
  }
  
  .tb_bd_collection tbody tr {
    background: #ffffff;
    border: 1px solid #dedede;
    border-right: unset;
    border-left: unset;
  }
  
  .tb_edit,
  .tb_remove {
    width: 6%;
  }
  
  .tb_main_body_part {
    width: 20%;
  }
  
  .tb_selected_body_part {
    width: 28%;
  }
  
  .tb_injury {
    width: 40%;
    padding: 15px 20px;
  }
  
  th.tb_injury.modal_tb_injury{
    padding: 15px;
  }
  
  .modal_tb_injury {
    width: 62%;
  }
  
  .modal_tb_remove {
    width: 10%;
  }
  
  .td_edit {
    border-left: unset;
    border-right: unset;
    text-align: center;
  }
  
  .td_selected_part, .td_injury {
    padding: 15px 0;
  }
  
  .td_injury {
    padding: unset;
    position: relative;
  }
  
  .td_injury .ng-select {
    padding: 15px;
  }
  
  .td_injury.modal_td_injury .ng-select {
    padding: 15px 0;
  }
  
  .td_injury ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 13px;
    margin: 0;
    list-style-type: none;
  }
  
  .td_injury ul li {
    background: #ececec;
    padding: 5px;
    margin: 5px;
    /*text-transform: capitalize;*/
    font-size: 12px;
  }
  
  .td_remove {
    border-right: unset;
  }
  
  .td_btn_edit, .td_btn_remove {
    width: 26px;
    height: 26px;
    /* background: #ffffff;
    border: 1px solid #a7aebb;
    border-radius: 3px; */
    position: relative;
    background: transparent;
    border: transparent;
    cursor: pointer;
  }
  
  .td_area, .td_injury, .td_remove, .td_selected_part {
    border-left: none;
    border-right: none;
  }
  
  .td_btn_edit svg, .td_btn_remove svg {
    margin-top: 2px;
  }
  
  .td_btn_edit::before {
    content: url(../../../../../assets/images/pen.png);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .ng-select .ng-select-container .ng-value-container, .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper, .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
    border-top: none !important;
  }
  
  .ng-select .ng-has-value .ng-placeholder, .ng-select.ng-select-opened .ng-placeholder {
    transform: translateY(-1.75em) scale(0.75) perspective(100px)
      translateZ(0.001px) !important;
  }
  
  .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
    transform: translateY(-1.75em) scale(0.75) perspective(100px)
      translateZ(0.001px) !important;
  }
  
  .ng-select .ng-select-container {
    min-height: 40px !important;
    border: 1px solid transparent !important;
    background-color: transparent !important;
    align-items: center !important;
    box-shadow: unset !important;
  }
  
  .ng-select.ng-select-opened .ng-select-container, .ng-select.ng-select-focused .ng-select-container {
    border: 1px solid #ccc !important;
    background-color: #ffffff !important;
  }
  .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
    padding-top: 0.4em !important;
  }
  
  .ng-select .ng-arrow-wrapper .ng-arrow {
    margin-top: 10px !important;
  }
  
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 7px !important;
  }
  
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    font-weight: 400 !important;
  }
  
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    display: flex !important;
    flex-wrap: wrap !important;
    background-color: #ececec !important;
    color: black !important;
    padding: 2px 0 !important;
    padding-left: 5px !important;
    font-size: 14px !important;
  }
  
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid transparent !important;
  }
  
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: #ccc !important;
    color: #000 !important;
  }
  
  /* clear and drop-down */
  .ng-clear-wrapper.ng-star-inserted, .ng-arrow-wrapper {
    visibility: hidden !important;
  }
  
  .mat-tab-body-content {
    /* overflow: auto !important; */
    overflow: hidden !important;
  }
  .mat-tab-labels {
    margin-left: 32%;
    display: block;
    position: relative;
    background: #f7f8f9;
    padding: 15px 8px;
    padding-top: 50px;
    padding-right: 0;
  }
  
  /* .mat-tab-labels::after {
    content: "VIEW";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 62%);
    color: #003da5;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.02em;
  } */
  
  .mat-tab-label {
    background-size: cover;
    /* width: 100px; */
    height: 65px !important;
    width: 23% !important;
    margin: 0 auto !important;
    flex: unset !important;
    position: relative;
    flex-grow: unset !important;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    border: 1px solid #dedede !important;
    opacity: 0.5 !important;
    object-fit: cover;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  .mat-tab-label-content{
    width: 100%;
  }
  
  .mat-tab-label-content img {
    width: 100%;
    height: 100%;
    transform: scale(1.3);
  }
  
  .mat-tab-label-active {
    opacity: 1 !important;
  }
  
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination, iframe#webpack-dev-server-client-overlay, .mat-ripple-element {
    display: none !important;
  }
  
  .modal_btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0 15px;
  }
  
  .modal_close {
    border: unset;
    background: transparent;
    position: absolute;
    /* right: 12px; 
    top: 15px; */
    right: 0;
    top: 36px;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  
  .modal_cancel {
    border: 1px solid #003da5;
    color: #003da5;
    background: transparent;
    font-size: 15px;
    padding: 17px 25px;
    border-radius: 50px;
    margin-right: 15px;
  }
  
  .modal_add {
    background-color: #003da5;
    color: #fff;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 25px;
    border: unset;
  }
  
  .modal_add span {
    font-size: 15px;
  }
  
  .modal_add svg {
    margin-left: 12px;
    width: 18px;
  }
  
  /* .modal_select_container {
    height: auto;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #dedede !important;
    margin-bottom: 30px;
  }
  
  .modal_select_container svg image{
    width: 250%;
  } */
  
  .modal_body_table_wrapper {
    /* overflow-y: auto;
    overflow-x: hidden; */
    height: unset;
    padding: 0;
  }
  
  .mat-tab-label:nth-child(4){
    margin-right: 0 !important;
  }
  
  .mat-tab-label-active:nth-child(odd):before, .mat-tab-label-active:nth-child(even)::before, .mat-tab-label-active:nth-child(odd):hover:before, .mat-tab-label-active:nth-child(even):hover::before {
    content: "";
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    background: #003da5;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    transition: ease-in-out opacity 1.5s;
  }
  
  .tab_content_left{
    height: 100%;
    width: 36%;
    position: relative;
    overflow: hidden;
    margin-right: auto;
    border: 1px solid #dedede !important;
  }
  
  .tab_content_left svg.body_fill_bg{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  
  .tab_content_left svg.body_fill_sm{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  
  .tab_content_left img.body_tab_img{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;
  }
  
  .tab_content_left img.body_tab_img.body_tab_img_male_front{
    top: -19px;
  }
  
  .modal_select_container {
    height: auto;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #dedede !important;
    margin-bottom: 30px;
    position: relative;
  }
  
  .modal_select_container svg, .modal_select_container g svg {
    height: 100%;
    width: 100%;
    display: block;
  }
  
  /* .modal_select_container svg {
    position: absolute;
    top: -45%;
    left: 50%;
    transform: translate(-50%, 50%);
    margin: 0 auto;
  }
  
  .modal_select_container img.modal_body_img {
    margin: 0 auto;
    position: relative;
    width: 331px;
    height: auto;
    z-index: -1;
  } */
  
  td.td_selected_part, td.td_area {
    padding: 15px 30px;
    text-align: left;
  }
  
  ._modal-open{
    overflow: hidden;
  }
  
  .custom_select{
    position: relative;
  }
  
  .mat-tab-label .mat-tab-label-content{
    position: relative;
  }
  
  .view_front, .view_back, .view_left, .view_right, .gender_male, .gender_female{
    transform: translate(50%, 68%);
    font-size: 16px;
    position: absolute;
    z-index: 3;
    right: 36px;
    color: #fff;
    opacity: 0.8;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    display: none;
  }
  
  .gender_male, .gender_female{
    bottom: 20px;
  }
  
  .active_female .gender_female, .active_male .gender_male{
    display: block;
  }
  
  .mat-tab-label:hover .view_front, .mat-tab-label:hover .view_back, .mat-tab-label:hover .view_left, .mat-tab-label:hover .view_right{
    display: none;
  }
  
  .mat-tab-label-active:hover .view_front, .mat-tab-label-active:hover .view_back, .mat-tab-label-active:hover .view_left, .mat-tab-label-active:hover .view_right{
    display: block;
  }
  
  .mat-tab-label.mat-tab-label-active .view_front, .mat-tab-label.mat-tab-label-active .view_back, .mat-tab-label.mat-tab-label-active .view_left, .mat-tab-label.mat-tab-label-active .view_right{
    display: block;
  }
  
  @media screen and (max-width: 1920px) {
    .modal_close {
      right: 0%;
    }
  }
  
  @media screen and (max-width: 1440px) {
    .mat-tab-labels{
      padding: 53px 13px 15px;
      margin-left: 32%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: center;
    }
  
    .mat-tab-label {
      min-width: unset !important;
      margin: 0 auto !important;
      width: 23%!important;
    }
  
    .mat-tab-label-content img {
      transform: scale(1.49);
    }
  
    .modal_close {
      right: 26%;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .body_table_main{
      height: 100%;
    }
  }
  
  @media screen and (max-width: 768px) {
    svg g path, svg g:hover path, svg g:hover polygon, svg g:hover rect {
      fill-opacity: 0.8;
    }
  
    .dashboard, .tooltip_body {
      display: none;
    }
  
    .body_table_outer_container, .gender_container, .tab_body_container {
      position: relative;
    }
  
    .body_section, .body_table_left, .body_table_right, .tab_content_left, .tab_content_right, .gender_container, .body_table_container {
      width: 100%;
    }
  
    .gender_container, .body_table_outer_container {
      left: unset;
      top: unset;
    }
  
    .tab_content_left svg {
      height: 250%;
    }
  
    .gender_container {
      width: 100%;
    }
  
    .body_table_wrapper {
      padding: 0;
    }
  
    .tab_content_left {
      margin-bottom: 35px;
      margin-top: 25px;
      height: 600px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  
    .modal_cancel, .modal_add span {
      font-size: 16px;
    }
  
    .modal_select_container {
      /* height: 600px; */
      height: auto;
      margin-bottom: 25px;
    }
  
    .modal_add svg {
      width: 17px;
    }
  
    .modal_container {
      padding-left: 35px;
      padding-right: 35px;
    }
  
    .tab_content_wrapper {
      margin-top: 0;
    }
  
    .modal_close {
      /* right: 6%; */
      right: 0;
    }
  
    .modal_select_container svg {
      height: 100%;
      width: 100%;
      display: block;
    }
  
    .modal_container::-webkit-scrollbar {
      width: 4px;
    }
  
    .mat-tab-label-content img {
      transform: scale(1.4);
    }
  
    .mat-tab-labels{
      display: flex;
      flex-wrap: wrap;
      margin-left: 0;
      justify-content: space-between;
      width: 100%;
      padding: 53px 0 15px;
    }
  
    .mat-tab-label {
      height: 60px !important;
      width: 23%!important;
      margin-left: 0;
      min-width: unset !important;
    }
  
    .tab_content_left img.body_tab_img{
      height: auto;
    }
  
    .modal_container h3{
      font-size: 22px;
    }
  
    .body_table_outer_container{
      right: 0;
    }
  
    .body_table_main::-webkit-scrollbar{
      height: 2px;
    }
  
    .mat-tab-label:nth-child(1) {
      margin-left: 0 !important;
    }
  
    .mat-tab-label:nth-child(4) {
      margin-right: 0 !important;
    }
  
    .txt_views {
      left: 42%;
      transform: translate(50%, 50%);
    }  
  
    .mat-tab-label.mat-tab-label-active .view_front, .mat-tab-label.mat-tab-label-active .view_back, .mat-tab-label.mat-tab-label-active .view_left, .mat-tab-label.mat-tab-label-active .view_right{
      display: block;
    }
  }
  
  @media screen and (max-width: 576px) {
    .mat-tab-labels {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 53px 0 15px;
    }
  
    .gender_container {
      width: 100%;
    }
  
    .mat-tab-label {
      width: 48% !important;
      margin-bottom: 15px !important;
    }
  
    .modal_body_table_wrapper, .body_table_wrapper.modal_body_table_wrapper{
      /* height: 225px; */
      height: unset;
    }
  
    .modal_container {
      /* width: calc(100vw - 100px);
      height: calc(90vh - 1rem);
      padding: 20px; */
      width: calc(100vw - 50px);
      height: calc(95vh - 1rem);
      padding: 15px;
    }
  
    .modal_container .span_area{
      font-size: 18px;
      margin-bottom: 25px;
    }
  
    /* .modal_select_container {
      height: 400px;
    } */
  
    .tab_content_left {
      height: 350px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-bottom: 25px;
      margin-top: 25px;
    }
  
    .modal_add svg {
      width: 20px;
    }
  
    .modal_close {
      /* right: 5%; */
      right: 0;
    }
  
    .tab_content_left img.body_tab_img.body_tab_img_male_front{
      top: -19px;
    }
  
    .tab_content_left svg {
      height: 250%;
    }
  
    
    td.td_selected_part, td.td_area {
      padding: 15px;
    }
  
    .mat-tab-label:nth-child(1) {
      margin-left: 0 !important;
    }
  
    .mat-tab-label:nth-child(2) {
      margin-right: 0 !important;
    }
  
    .mat-tab-label:nth-child(3) {
      margin-left: 0 !important;
    }
  
    .mat-tab-label:nth-child(4) {
      margin-left: 0 !important;
    }
  
    .txt_wrapper span, .mat-tab-labels::after{
      font-size: 18px;
    }
  
    .txt_views {
      left: 40%;
      font-size: 18px;
    }  
  }
  
  @media screen and (max-width: 490px) {
    ._modal {
      overflow: hidden;
    }
  
    .body_wrapper {
      padding: 0 20px;
    }
  
    .mat-tab-labels{
      padding: 53px 0 0;
    }
  
    .mat-tab-label {
      margin: 0 6px !important;
      margin-bottom: 15px !important;
    }
  
    .gender_container {
      width: 100%;
    }
  
    .modal_close {
      right: 6%;
    }
  
    /* .tab_content_left svg.body_fill_bg.body_fill_bg_male_front, .tab_content_left svg.body_fill_sm.body_fill_sm_male_front {
      height: 270%;
    } */
  
    .modal_body_table_wrapper::-webkit-scrollbar{
      height: 4px;
    }
  
    .body_table_wrapper{
      overflow: auto;
    }
  
    .body_table_wrapper.modal_body_table_wrapper{
      width: 100%;
      overflow: auto;
    }
  
    .body_table_wrapper table{
      width: 550px;
    }
  
    .txt_views {
      left: 37%;
    }  
  }
  
  @media screen and (max-width: 425px) {
    .txt_wrapper span, .mat-tab-labels::after {
      font-size: 16px;
    }
  
    .gender_container {
      width: 100%;
    }
  
    .mat-tab-label {
      margin: 0 4px 15px!important;
      margin-bottom: 12px !important;
    }
  
    .txt_views {
      left: 36%;
      font-size: 16px;
    }  
  }
  
  @media screen and (max-width: 414px) {
    /* .tab_content_left svg.body_fill_bg.body_fill_bg_male_front, .tab_content_left svg.body_fill_sm.body_fill_sm_male_front {
      height: 223%;
    } */
  
    .tab_content_left img.body_tab_img.body_tab_img_male_front {
      width: 91%;
      top: -16px;
    }
  }

  /* "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" */
  /* 'Helvetica Neue', sans-serif */

#error-message{
  color: #E74C3C !important;
  font-family: "Verdana", "Helvetica Neue", Arial, "Microsoft YaHei", sans-serif;
}